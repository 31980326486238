import React, { useState } from 'react';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledModal, WrapperButtons } from './styles';

export default function ModalAprovar({
  isOpenModal,
  handleCloseModal,
  confirmTransaction,
}) {
  const [showLoading] = useState(false);

  return (
    <>
      <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
        <Loading showLoading={showLoading} />

        <StyledModal.Header closeButton>
          <StyledModal.Title>Confirmação de Transação</StyledModal.Title>
        </StyledModal.Header>

        <StyledModal.Body>
          <div>
            <p>Deseja confirmar a transação?</p>
          </div>
          <WrapperButtons>
            <Button
              typeOfButton="secondary"
              type="button"
              onClick={() => confirmTransaction()}
            >
              Sim
            </Button>
            <Button typeOfButton="secondary" onClick={handleCloseModal}>
              Não
            </Button>
          </WrapperButtons>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
}
