import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../Button';
import Loading from '../../Loading';
import constants from '../../../config';

import { StyledModal, WrapperButtons, Form } from './styles';

import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalUpdateRule({
  rule,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [ruleName, setRuleName] = useState(rule.ruleName);
  const [ruleOrder, setRuleOrder] = useState(rule.ruleOrder);
  const [ruleMinPercentageValue, setRuleMinPercentageValue] = useState(
    rule.minPercentageValue,
  );
  const [showLoading, setShowLoading] = useState(false);
  const FULL_URL = window.SERVICES_DOMAIN + window.CASHPLUSPOINT_RULE_URL;

  const handleRuleName = e => {
    setRuleName(e.target.value);
  };

  const handleRuleOrder = e => {
    setRuleOrder(e.target.value);
  };

  const handleRuleMinPercentageValue = e => {
    const inputValue = e.target.value;
    if (inputValue === '' || Number(inputValue) >= 0) {
      setRuleMinPercentageValue(inputValue);
    }
  };

  const handleAddCppRule = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CASHPLUSPOINT_RULES.CREATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          ruleType: rule.ruleType,
          ruleId: rule.ruleId,
          siteId: rule.siteId,
          ruleName,
          ruleOrder,
          minPercentageValue: ruleMinPercentageValue,
        },
      };

      handlePostRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data && response.data.success === false)
            throw new Error('Generic error');
          handleCloseModal('success', 'Sucesso!', `Regra de C+P Atualizada!`);
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`Error at addCppRule request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Ativar/Atualizar Regra</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              type="text"
              value={constants.CASHPLUSPOINT_RULE_TYPES[rule.ruleType]}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Identificador</Form.Label>
            <Form.Control type="text" value={rule.ruleId} disabled />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={ruleName || ''}
              onChange={handleRuleName}
              placeholder="Digite o nome para regra"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Prioridade</Form.Label>
            <Form.Control
              type="number"
              min="0"
              value={ruleOrder || ''}
              onChange={handleRuleOrder}
              placeholder="Digite a posição da regra"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Valor Minimo %</Form.Label>
            <Form.Control
              type="number"
              min="0"
              max="100"
              step="1"
              value={ruleMinPercentageValue || ''}
              onChange={handleRuleMinPercentageValue}
              placeholder="Digite o valor da porcentagem minima para regra"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Site</Form.Label>
            <Form.Control
              type="text"
              value={constants.CPP_SITE_ID[rule.siteId]}
              disabled
            />
          </Form.Group>
        </Form>

        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            disabled={!ruleMinPercentageValue}
            onClick={handleAddCppRule}
          >
            Salvar
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
