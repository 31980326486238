import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../Button';
import Loading from '../../Loading';
import constants from '../../../config';

import { StyledModal, WrapperButtons, Form } from './styles';

import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalAddRule({
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [ruleId, setRuleId] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [ruleType, setRuleType] = useState('SKU');
  const [ruleSiteId, setRuleSiteId] = useState('ESFERA');
  const [ruleOrder, setRuleOrder] = useState(99);
  const [ruleMinPercentageValue, setRuleMinPercentageValue] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const FULL_URL = window.SERVICES_DOMAIN + window.CASHPLUSPOINT_RULE_URL;

  const handleRulelistType = e => {
    setRuleType(e.target.value);
  };

  const handleRuleId = e => {
    setRuleId(e.target.value);
  };

  const handleRuleName = e => {
    setRuleName(e.target.value);
  };

  const handleRuleOrder = e => {
    setRuleOrder(e.target.value);
  };

  const handleRuleMinPercentageValue = e => {
    const inputValue = e.target.value;
    if (inputValue === '' || Number(inputValue) >= 0) {
      setRuleMinPercentageValue(inputValue);
    }
  };

  const handleRuleSiteId = e => {
    setRuleSiteId(e.target.value);
  };

  const handleAddCppRule = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CASHPLUSPOINT_RULES.CREATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          ruleType,
          ruleId,
          ruleName,
          ruleOrder,
          minPercentageValue: ruleMinPercentageValue,
          siteId: ruleSiteId,
        },
      };

      handlePostRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data && response.data.success === false)
            throw new Error('Generic error');
          setRuleId('');
          setShowLoading(false);
          setRuleType('SKU');
          setRuleMinPercentageValue('');
          setRuleName('');
          setRuleOrder(99);
          setRuleSiteId('ESFERA');
          handleCloseModal('success', 'Sucesso!', `Regra de C+P Atualizada!`);
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`Error at addCppRule request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Cadastrar/Atualizar Regra</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              value={ruleType}
              onChange={handleRulelistType}
            >
              {Object.keys(constants.CASHPLUSPOINT_RULE_TYPES).map(item => (
                <option value={item} key={item}>
                  {constants.CASHPLUSPOINT_RULE_TYPES[item]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Identificador</Form.Label>
            <Form.Control
              type="text"
              value={ruleId || ''}
              onChange={handleRuleId}
              placeholder="Digite o id da regra"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={ruleName || ''}
              onChange={handleRuleName}
              placeholder="Digite o nome para regra"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Prioridade</Form.Label>
            <Form.Control
              type="number"
              min="0"
              value={ruleOrder || ''}
              onChange={handleRuleOrder}
              placeholder="Digite a posição da regra"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Valor Minimo %</Form.Label>
            <Form.Control
              type="number"
              min="0"
              max="100"
              step="1"
              value={ruleMinPercentageValue || ''}
              onChange={handleRuleMinPercentageValue}
              placeholder="Digite o valor da porcentagem minima para regra"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Site</Form.Label>
            <Form.Control
              as="select"
              value={ruleSiteId || ''}
              onChange={handleRuleSiteId}
            >
              {Object.keys(constants.CPP_SITE_ID).map(item => (
                <option value={item} key={item}>
                  {constants.CPP_SITE_ID[item]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>

        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            disabled={!ruleId}
            onClick={handleAddCppRule}
          >
            Salvar
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
