import React, { useState } from 'react';
import moment from 'moment';
import { TbEye } from 'react-icons/tb';

import constants from '../../../config';
import ModalInactiveRule from '../ModalInactiveRule';
import ModalCppRuleHistory from '../ModalCppRuleHistory';
import ModalUpdateRule from '../ModalUpdateRule';

import { StyledTr, WrapperButton } from './styles';

export default function CppRuleItem({
  rule,
  userPermissions,
  handleShowMessageAction,
}) {
  const [selectedRule, setSelectedRule] = useState(null);
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [isOpenModalInactive, setIsOpenModalRemove] = useState(false);
  const [isOpenModalUpdateRule, setIsOpenModalUpdateRule] = useState(false);
  const isRuleActive = rule.active;

  const handleShowMdlInactiveRule = event => {
    event.stopPropagation();
    setIsOpenModalRemove(true);
  };

  const handleCloseMdlInactiveRule = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalRemove(false);
  };

  const handleShowMdlModalHistory = ruleId => {
    setSelectedRule(ruleId);
    setIsOpenModalHistory(true);
  };
  const handleCloseMdlModalHistory = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalHistory(false);
  };

  const handleShowMdlUpdateRule = () => {
    setIsOpenModalUpdateRule(true);
  };

  const handleCloseMdlUpdateRule = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalUpdateRule(false);
  };

  return (
    <>
      <StyledTr isActive={isRuleActive}>
        <td>{rule.ruleName}</td>
        <td>{constants.CPP_RULE_TYPES[rule.ruleType]}</td>
        <td>{rule.ruleId}</td>
        <td>{rule.cpp}</td>
        <td>{rule.ruleOrder}</td>
        <td>{rule.siteId}</td>
        <td>{rule.user}</td>
        <td>{moment(rule.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
        <td className="td-center ">
          <button
            className="btn-history"
            type="button"
            onClick={() => handleShowMdlModalHistory(rule.id)}
            aria-label="Ver detalhes"
          >
            <TbEye />
          </button>
        </td>
        <td>
          <WrapperButton>
            {userPermissions.checkUiPermissionsFn(
              'ESFERA.CPP_RULES.DELETE',
              userPermissions.userGroups,
            ) && isRuleActive ? (
              <button
                type="button"
                className="btn-action"
                onClick={handleShowMdlInactiveRule}
              >
                <span className="recused">Inativar</span>
              </button>
            ) : null}
            {userPermissions.checkUiPermissionsFn(
              'ESFERA.CPP_RULES.CREATE',
              userPermissions.userGroups,
            ) ? (
              <button
                type="button"
                className="btn-action"
                onClick={handleShowMdlUpdateRule}
              >
                <span className="update">Alterar</span>
              </button>
            ) : null}
          </WrapperButton>
        </td>
      </StyledTr>

      <ModalUpdateRule
        rule={rule}
        isOpenModal={isOpenModalUpdateRule}
        handleCloseModal={handleCloseMdlUpdateRule}
        userPermissions={userPermissions}
      />

      <ModalInactiveRule
        rule={rule}
        isOpenModal={isOpenModalInactive}
        handleCloseModal={handleCloseMdlInactiveRule}
        userPermissions={userPermissions}
      />

      {isOpenModalHistory && (
        <ModalCppRuleHistory
          ruleId={selectedRule}
          isOpenModal={isOpenModalHistory}
          handleCloseModal={handleCloseMdlModalHistory}
          userPermissions={userPermissions}
        />
      )}
    </>
  );
}
