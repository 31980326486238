import styled from 'styled-components';

import { Form, Table } from 'react-bootstrap';
import Button from '../../components/Button';

export const Container = styled.main`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;

  h2 {
    font-size: 22px;
    color: var(--gray-50);
    font-weight: 700;
  }

  hr {
    margin-top: 25px;
  }

  .center-text-container {
    display: flex;
    justify-content: center;
    padding-top: 34px;
  }

  .center-text {
    color: rgba(34, 34, 34, 0.87);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 16px;
  }

  .modal-text {
    color: #000;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 16px;
  }
`;

export const PricingCppFormComponent = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .form-select {
    width: 284px;
    height: 43px;
  }

  input {
    width: 284px;
    height: 43px;
  }

  .form-label {
    margin-bottom: 0 !important;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .invalid-text {
    color: #f14c6a;
    font-size: 12px;
  }

  button {
    width: 200px;
    height: 43px;
  }
`;

export const StyledTable = styled(Table)`
  font-size: 0.875rem;

  th {
    border-top: 0;
    vertical-align: center;
  }
`;
