import styled from 'styled-components';

import { Table } from 'react-bootstrap';

export const StyledTable = styled(Table)`
font-size: 0.855rem;
  margin-top: 6px;
  font-family: Poppins;

  .content {
    font-size: 0.855rem;
    width: 274px;
    white-space: nowrap;
    
  }

  th {
    border-top: 0;
    vertical-align: center;
  }
`;
