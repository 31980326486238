import React, { useEffect, useState } from 'react';
import { AccordionStyle } from './styles';

export default function Accordion({ title, bodyAccordion }) {
  const [isActive, setIsActive] = useState(false);

  const [collapseClass, setCollapseClass] = useState('collapse');

  useEffect(() => {
    setCollapseClass(isActive ? 'collapse show' : 'collapse');
  }, [isActive]);
  return (
    <AccordionStyle onClick={() => setIsActive(prevState => !prevState)}>
      <div className="title">
        <button type="button" className="button">
          {title}
        </button>
        {isActive ? (
          <span className="icon-button esfera-icon ic_system_arrow_down icon" />
        ) : (
          <span className="icon-button esfera-icon ic_system_arrow_top icon" />
        )}
      </div>

      <div className={collapseClass}>{bodyAccordion}</div>
    </AccordionStyle>
  );
}
