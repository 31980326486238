import styled from 'styled-components';

import { Form } from 'react-bootstrap';

export const StyledForm = styled(Form)`

  img {
    width: 30px;
    height: 30px;
    margin-right: 32px;
  }
  .csv-link {
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 95%;
    color: transparent;
    background-color: transparent;
    border-color: transparent;
  }
`;
