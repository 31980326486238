import React from 'react';

import { Container } from './styles';

import footerImg from '../../../../assets/nucleo/sidemenu/Reduced.svg';

export default function Footer() {
  return (
    <Container>
      <div className="footer__box">
        <img
          className="footer__img"
          src={footerImg}
          alt="santander-logo-reduced"
        />
        <div className="footer__text">© Esfera Fidalidade S.A.</div>
      </div>
    </Container>
  );
}
