import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../Button';
import {
  handleGetRequest,
  handlePutRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';
import { StyledForm } from './styles';

export default function MaximumPointLimitForm({
  userPermissions,
  populateToastMessages,
}) {
  const [generalLimitPoint, setGeneralLimitPoint] = useState('');
  const [generalClubLimitPoint, setGeneralClubLimitPoint] = useState('');
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.MAX_LIMIT_BUY_URL;
  const FULL_URL_UPDATE =
    window.SERVICES_DOMAIN + window.MAX_LIMIT_BUY_UPDATE_URL;

  const formatLimitField = value => {
    const formattedField = value.replace(/[^0-9]/g, '');

    const maskedField = formattedField.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return maskedField;
  };

  const handleLimitMaxPoints = e => {
    const inputLimit = e.target.value;

    const maskedField = formatLimitField(inputLimit);
    setGeneralLimitPoint(maskedField);
  };

  const handleLimitMaxClubPoints = e => {
    const inputLimit = e.target.value;

    const maskedField = formatLimitField(inputLimit);
    setGeneralClubLimitPoint(maskedField);
  };

  const handleSetLimits = responseLimitvalues => {
    responseLimitvalues.forEach(function (limitValue) {
      if (limitValue.code === 'PP_LIMIT_DEFAULT')
        setGeneralLimitPoint(limitValue.maxQuantity);
      if (limitValue.code === 'PP_LIMIT_CLUB_DEFAULT')
        setGeneralClubLimitPoint(limitValue.maxQuantity);
    });
  };

  const getBuyLimitPoints = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.LIMIT_BUY_POINTS.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.body)
            handleSetLimits(response.data.body);
        })
        .catch(error => {
          console.log(`Error at listLimits request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handleUpdateLimits = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.LIMIT_BUY_POINTS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      const formRequest = [
        {
          maxQuantity: generalLimitPoint.replace(/\./g, ''),
          code: 'PP_LIMIT_DEFAULT',
        },
        {
          maxQuantity: generalClubLimitPoint.replace(/\./g, ''),
          code: 'PP_LIMIT_CLUB_DEFAULT',
        },
      ];
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: formRequest,
      };
      handlePutRequest(FULL_URL_UPDATE, reqOptions)
        .then(response => {
          if (response && response.data) {
            populateToastMessages(
              'success',
              'Sucesso!',
              'Limites atualizados com sucesso!',
              'main',
            );
          }
        })
        .catch(error => {
          console.log(`Error at listLimits request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    getBuyLimitPoints();
  }, []);

  return (
    <StyledForm>
      <div className="form-content">
        <div className="inputs-group">
          <StyledForm.Label>Quantidade Máxima para Compra: </StyledForm.Label>
          <StyledForm.Group className="form-group">
            <StyledForm.Control
              onChange={handleLimitMaxPoints}
              value={generalLimitPoint}
              maxLength={10}
              type="text"
              required
            />
          </StyledForm.Group>
        </div>
        <div className="inputs-group">
          <StyledForm.Label>
            Quantidade Máxima para Compra <strong>Usuário Clube:</strong>
          </StyledForm.Label>
          <StyledForm.Group className="form-group">
            <StyledForm.Control
              onChange={handleLimitMaxClubPoints}
              value={generalClubLimitPoint}
              maxLength={10}
              type="text"
              required
            />
          </StyledForm.Group>
        </div>
      </div>

      <div className="form-actions">
        <Button
          type="button"
          typeOfButton="primary"
          onClick={handleUpdateLimits}
          disabled={!generalLimitPoint}
        >
          Aplicar Limite
        </Button>
      </div>
    </StyledForm>
  );
}
