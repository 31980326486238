import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { parseInt } from 'lodash';
import moment from 'moment';
import OrdersFilter from '../../components/CashbackAwinPage/OrdersFilter';
import OrdersTable from '../../components/CashbackAwinPage/OrderTable';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import {
  handleGetRequest,
  initialPaginationData,
  initialHeadersData,
} from '../../services/GenericMethods';
import { Container } from './styles';
import ToastCustom from '../../components/Toast';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function ManualOrders({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [ordersFormatted, setOrdersFormatted] = useState([]);
  const [ordersFormattedPerPage, setOrdersFormattedPerPage] = useState([]);
  const [partnerName, setPartnerName] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [amountFormattedMin, setAmountFormattedMin] = useState();
  const [amountFormattedMax, setAmountFormattedMax] = useState();
  const [statustype, setStatusType] = useState();
  const options = initialPaginationData('GET', 0, keycloak.token);
  const FULL_URL = window.SERVICES_DOMAIN + window.REPORT_AWIN_TYPE_URL;

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  const calcTotalPages = orders => {
    const totalOrders = orders.length;
    const totalPagesCalculated = totalOrders / 10;

    if (Number.isInteger(totalPagesCalculated)) return totalPagesCalculated;
    if (totalPagesCalculated <= 1) return 0;

    return Math.ceil(totalPagesCalculated);
  };

  const getAllPartnerNames = orders => {
    const arrayPartner = [];
    orders.forEach(value => {
      const exist = arrayPartner.indexOf(value.partnerName) >= 0;
      if (!exist) arrayPartner.push(value.partnerName);
    });
    return arrayPartner;
  };

  const getOrdersAwin = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'AWIN.ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.length > 0) {
            const orders = response.data;

            setCurrentPage(0);
            orders.forEach((order, index) => {
              if (
                order.awinPostReturn.clickRef === null ||
                order.awinPostReturn.clickRef === ''
              ) {
                orders[index].status = 'noClickref';
              } else orders[index].status = 'withClickref';
            });

            orders.sort((a, b) => {
              if (a.status < b.status) {
                return -1;
              }
              if (a.status > b.status) {
                return 1;
              }
              return 0;
            });

            setOrdersFormatted(orders);
            setPartnerName(getAllPartnerNames(orders));
            setTotalPages(calcTotalPages(orders));
            setOrdersFormattedPerPage(orders.slice(0, 10));
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setOrdersFormatted([]);
            setOrdersFormattedPerPage([]);
            setPartnerName([]);
          }

          console.warn(`Response listOrders = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const statusVerify = value => {
    const statusDictionary = {
      'já associado': 'withClickref',
      'ja associado': 'withClickref',
      associado: 'withClickref',
      'associar cliente': 'noClickref',
      associar: 'noClickref',
    };
    return statusDictionary[value];
  };

  const handleFilterAmount = (ordersFilter, amountMin, amountMax) => {
    setAmountFormattedMin(amountMin);
    setAmountFormattedMax(amountMax);
    return ordersFilter.filter(value => {
      return (
        value.awinPostReturn.transactionAmount >= parseInt(amountMin) &&
        value.awinPostReturn.transactionAmount <= parseInt(amountMax)
      );
    });
  };

  const handleFilterTransaction = (ordersFilter, transaction) => {
    const ordersFilterTransaction = [];
    ordersFilter.forEach(value => {
      if (value.awinPostReturn.transactionId === transaction)
        ordersFilterTransaction.push(value);
    });
    return ordersFilterTransaction;
  };
  const handleFilterStatus = (ordersFilter, status) => {
    const statusText = statusVerify(status.toLowerCase());
    setStatusType(statusText);

    const ordersFilterStatus = ordersFilter.filter(value => {
      return value.status === statusText;
    });

    return ordersFilterStatus;
  };

  function verifyDatesByRange(parsedStartDate, parsedEndDate) {
    if (parsedStartDate.isAfter(parsedEndDate)) {
      handleShowMessageAction(
        'error',
        'Erro ao Filtrar data',
        'data de início maior que a fim',
      );
    } else if (parsedEndDate.isBefore(parsedEndDate)) {
      handleShowMessageAction(
        'error',
        'Erro ao Filtrar data',
        'data fim menor que a início',
      );
    }
  }

  function filterDatesByRange(startDate, endDate, datesArray) {
    const parsedStartDate = moment(startDate);
    const parsedEndDate = moment(endDate);
    verifyDatesByRange(parsedStartDate, parsedEndDate);

    return datesArray.filter(item => {
      const itemDate = moment(item.awinPostReturn.transactionDate);
      const isSame =
        itemDate.isSame(startDate, 'day') && itemDate.isSame(endDate, 'day');
      const isBetween = itemDate.isBetween(startDate, endDate, 'day', '[]');

      return isSame || isBetween;
    });
  }

  const handleFilterPartner = (ordersFilter, partner) => {
    const ordersFilterPartner = [];
    ordersFilter.forEach(value => {
      if (value.partnerName === partner) ordersFilterPartner.push(value);
    });
    return ordersFilterPartner;
  };

  const handleFilter = inputParams => {
    let ordersFilter = ordersFormatted;
    setStatusType();
    setOrdersFormattedPerPage([]);
    setAmountFormattedMin();
    setAmountFormattedMax();

    if (inputParams.saleAmountMin && inputParams.saleAmountMax) {
      ordersFilter = handleFilterAmount(
        ordersFilter,
        inputParams.saleAmountMin,
        inputParams.saleAmountMax,
      );
    } else if (inputParams.saleAmountMin) {
      handleShowMessageAction(
        'error',
        'Erro ao filtrar',
        'Precisa informar o valor Maximo',
      );
    } else if (inputParams.saleAmountMax) {
      handleShowMessageAction(
        'error',
        'Erro ao filtrar',
        'Precisa informar o valor Mínimo',
      );
    }

    if (inputParams.transaction) {
      ordersFilter = handleFilterTransaction(
        ordersFilter,
        inputParams.transaction,
      );
    }
    if (inputParams.status) {
      ordersFilter = handleFilterStatus(ordersFilter, inputParams.status);
    }
    if (inputParams.endDate && inputParams.startDate) {
      ordersFilter = filterDatesByRange(
        inputParams.startDate,
        inputParams.endDate,
        ordersFilter,
      );
    } else if (inputParams.endDate) {
      handleShowMessageAction(
        'error',
        'Erro ao Filtrar data',
        'Informe a data início',
      );
    } else if (inputParams.startDate) {
      handleShowMessageAction(
        'error',
        'Erro ao Filtrar data',
        'Informe a data fim',
      );
    }

    if (inputParams.partner) {
      ordersFilter = handleFilterPartner(ordersFilter, inputParams.partner);
    }
    setTotalPages(calcTotalPages(ordersFilter));
    setCurrentFilter(inputParams);
    setOrdersFormattedPerPage(ordersFilter.slice(0, 10));
  };

  const handlePageChange = page => {
    const offset = page * 10;
    setOrdersFormattedPerPage([]);
    setCurrentPage(page);
    setOrdersFormattedPerPage(ordersFormatted.slice(offset, offset + 10));
  };

  useEffect(() => {
    getOrdersAwin(options);
  }, [toastList]);

  const amountFormatMinMax = ordersFormattedPerPage.filter(value => {
    return (
      value.awinPostReturn.transactionAmount >= parseInt(amountFormattedMin) &&
      value.awinPostReturn.transactionAmount <= parseInt(amountFormattedMax)
    );
  });
  const ordersFilterStatusFormattedPage = ordersFormattedPerPage.filter(
    value => {
      return value.status === statustype;
    },
  );

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <h2>Análise de Pedidos Awin</h2>
      <OrdersFilter
        allPartner={partnerName}
        currentFilter={currentFilter}
        handleFilter={handleFilter}
      />
      {userPermissions.checkUiPermissionsFn(
        'AWIN.ORDERS.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {ordersFormattedPerPage.length > 0 ? (
            <OrdersTable
              orders={
                // eslint-disable-next-line no-nested-ternary
                amountFormatMinMax.length
                  ? amountFormatMinMax
                  : ordersFilterStatusFormattedPage.length
                  ? ordersFilterStatusFormattedPage
                  : ordersFormattedPerPage
              }
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
              setOrdersFormattedPerPage={setOrdersFormattedPerPage}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={false}
          isLastPage={false}
          onClickFnPage={handlePageChange}
        />
      ) : null}
    </Container>
  );
}
