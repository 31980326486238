import React from 'react';
import {
  formatDate,
  maskCPF,
  maskCNPJ,
} from '../../../helpers/GlobalFunctions';

import Modal from '../../Modal';
import { Form } from './styles';
import Accordion from '../../AccordionDetails';
import CashbackStatus from '../CashbackStatus';

export default function ModalDetails({
  isOpenModal,
  handleCloseModal,
  modalDetailData,
  cashback,
}) {
  return (
    <Modal
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      headerTitle="Detalhes da Transação"
    >
      <Form>
        <Form.Group>
          <Form.Label>
            <b>Cliente: </b> {cashback?.clientName}
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b> CPF/CNPJ: </b> {cashback?.documentId}
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b> Parceiro: </b> {cashback?.partnerName}
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b> Valor da Compra: </b> {cashback?.productValue}
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b> Valor do Cashback: </b> {cashback?.cashbackValue}
          </Form.Label>
        </Form.Group>
        <div className="outside-accordion">
          {modalDetailData &&
            modalDetailData.map(e => {
              return (
                <Accordion
                  key={`${e.paymentDate}${e.requestDate}`}
                  title={
                    <div className="title">
                      Solicitado em{' '}
                      {formatDate(
                        e.requestDate,
                        'YYYY-MM-DD HH:mm:ss',
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                      <CashbackStatus status={e.status} />
                    </div>
                  }
                  bodyAccordion={
                    <div className="body-accordion">
                      <div>
                        <b>Tipo da chave pix: </b> {e.pixType}
                      </div>
                      <div>
                        <b>Chave pix: </b>
                        {e.pix.length === 14 ? maskCNPJ(e.pix) : maskCPF(e.pix)}
                      </div>
                      <div>
                        {e.paymentDate && (
                          <div>
                            <b>Data da transferencia: </b>
                            {formatDate(
                              e.paymentDate,
                              'YYYY-MM-DD HH:mm:ss',
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              );
            })}
        </div>
      </Form>
    </Modal>
  );
}
