import React, { useState } from 'react';
import OrderItem from '../OrderItem';
import { StyledTable } from './styles';

export default function EsferaTable({
  orders,
  userPermissions,
  handleShowMessageAction,
  setOrdersFormattedPerPage,
}) {
  const [ascOrder, setAscOrder] = useState(true);

  const sortingOptions = {
    transactionId: () =>
      orders.sort((a, b) =>
        ascOrder
          ? a.awinPostReturn.transactionId - b.awinPostReturn.transactionId
          : b.awinPostReturn.transactionId - a.awinPostReturn.transactionId,
      ),
    transactionAmount: () =>
      orders.sort((a, b) =>
        ascOrder
          ? a.awinPostReturn.transactionAmount -
            b.awinPostReturn.transactionAmount
          : b.awinPostReturn.transactionAmount -
            a.awinPostReturn.transactionAmount,
      ),
    transactionDate: () =>
      orders.sort((a, b) =>
        ascOrder
          ? new Date(a.awinPostReturn.transactionDate) -
            new Date(b.awinPostReturn.transactionDate)
          : new Date(b.awinPostReturn.transactionDate) -
            new Date(a.awinPostReturn.transactionDate),
      ),
    commission: () =>
      orders.sort((a, b) =>
        ascOrder
          ? a.awinPostReturn?.commission - b.awinPostReturn?.commission
          : b.awinPostReturn?.commission - a.awinPostReturn?.commission,
      ),
    partnerName: () =>
      orders.sort((a, b) =>
        ascOrder
          ? a.partnerName?.localeCompare(b.partnerName)
          : b.partnerName?.localeCompare(a.partnerName),
      ),
    status: () =>
      orders.sort((a, b) =>
        ascOrder
          ? a.status?.localeCompare(b.status)
          : b.status?.localeCompare(a.status),
      ),
  };

  function sortTable(value) {
    const selectedFn = sortingOptions[value];
    setOrdersFormattedPerPage(orders);
    setAscOrder(!ascOrder);

    if (selectedFn) {
      selectedFn();
    } else {
      setOrdersFormattedPerPage(orders);
    }
  }

  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th onClick={() => sortTable('transactionId')}>ID Transação</th>
          <th onClick={() => sortTable('transactionAmount')}>
            Valor do Pedido
          </th>
          <th onClick={() => sortTable('commission')}>Valor da Comissão</th>
          <th onClick={() => sortTable('transactionDate')}>Data do Pedido</th>
          <th onClick={() => sortTable('partnerName')}>Loja</th>
          <th onClick={() => sortTable('status')}>Status</th>
        </tr>
      </thead>

      <tbody>
        {orders.map(order => (
          <OrderItem
            order={order}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
