import styled from 'styled-components';

export const StyledTr = styled.tr`
  cursor: pointer;
  vertical-align: center;
  font-family: Poppins;

  .content {
    text-align: center;
  }

  .documentId {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
  }
  td {
    vertical-align: middle;
    white-space: pre-line;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;

  span {
    font-weight: 600;
    font-size: 0.8rem;

    &.confirm {
      color: var(--green-50);
    }

    &.recused {
      color: var(--boston);
    }
  }

  > span {
    margin-left: 10px;
  }

  .btn-action {
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 7px 2px;
    background: transparent;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    transition: background 0.2s;
    width: 185px;
    height: 38px;
    color: #1e7e34;

    &:hover {
      background: var(--gray-500);
    }

    & + .btn-action {
      margin-left: 10px;
    }

    img {
      margin-bottom: 2px;
      width: 20px;
      height: 20px;
    }
  }

  .btn-action2 {
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 8px 2px;
    background: transparent;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    transition: background 0.2s;
    width: 185px;
    height: 38px;
    color: #cc0000;
    margin-top: 5px;

    &:hover {
      background: var(--gray-500);
    }

    & + .btn-action {
      margin-left: 10px;
    }

    img {
      margin-bottom: 2px;
      width: 20px;
      height: 20px;
    }
  }
`;
