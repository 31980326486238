import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Modal } from 'react-bootstrap';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../../helpers/GlobalFunctions';
import {
  handleGetRequest,
  handlePutRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { PricingCppFormComponent } from '../styles';
import Button from '../../../components/Button';

const FULL_URL = `${window.SERVICES_DOMAIN}${window.CPPS_URL}`;

const getErrorToasts = (err, keycloak) =>
  populateToastMessagesGlobal(
    'error',
    'Erro',
    formatErrorMessageToToast(err, keycloak).messagePTBR,
    'main',
  );

const PricingCppForm = ({
  selectedOption,
  setSelectedOption,
  setToastList,
  setTableData,
  loadingTableData,
  setLoadingTableData,
}) => {
  const { keycloak } = useKeycloak();

  const fetchSeller = useRef(true);

  const [sellers, setSellers] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [selectedSellerId, setSelectedSellerId] = useState();
  const [selectedSellerIdError, setSelectedSellerIdError] = useState('');
  const [cpp, setCpp] = useState('');
  const [cppError, setCppError] = useState('');
  const [freight, setFreight] = useState('');
  const [freightError, setFreightError] = useState('');

  const getSellers = useCallback(
    async () =>
      handleGetRequest(`${FULL_URL}/sellers`, {
        headers: initialHeadersData(keycloak.token),
      })
        .then(({ data }) => setSellers(data))
        .catch(err => setToastList(t => [...t, getErrorToasts(err, keycloak)])),
    [keycloak],
  );

  useEffect(() => {
    if (fetchSeller.current) {
      fetchSeller.current = false;
      getSellers();
    }
  }, [getSellers]);

  const handleChangeOption = e => {
    setSelectedOption(e.target.value);
    setTableData([]);
    setSelectedSellerId(undefined);
    setSelectedSellerIdError('');
    setCppError('');
    setFreightError('');
  };

  const handleChangeSeller = e => {
    if (e.target.value === 'seller') {
      setSelectedSellerId(undefined);
      return;
    }

    setSelectedSellerId(e.target.value);
  };

  const handleHistory = async () => {
    setLoadingTableData(true);
    setSelectedSellerIdError('');

    if (!selectedSellerId) {
      setSelectedSellerIdError('Selecione um parceiro para continuar');
      setLoadingTableData(false);
      return;
    }

    handleGetRequest(`${FULL_URL}/revisions/${selectedSellerId}`, {
      headers: initialHeadersData(keycloak.token),
    })
      .then(({ data }) => setTableData(data))
      .catch(err => setToastList(t => [...t, getErrorToasts(err, keycloak)]))
      .finally(() => setLoadingTableData(false));
  };

  const handleChange = async () => {
    setLoadingTableData(true);

    handlePutRequest(`${FULL_URL}/${selectedSellerId}`, {
      headers: initialHeadersData(keycloak.token),
      data: {
        cpp: Number(cpp),
        freight: Number(freight),
      },
    })
      .then(() => {
        setToastList(t => [
          ...t,
          populateToastMessagesGlobal(
            'success',
            'Sucesso',
            'Alteração concluída',
          ),
        ]);
        setSelectedSellerId();
        setCpp('');
        setFreight('');
      })
      .catch(err => setToastList(t => [...t, getErrorToasts(err, keycloak)]))
      .finally(() => {
        setShowConfirmModal(false);
        setLoadingTableData(false);
      });
  };

  const handleConsult = async () => {
    setLoadingTableData(true);

    handleGetRequest(`${FULL_URL}/${selectedSellerId || ''}`, {
      headers: initialHeadersData(keycloak.token),
    })
      .then(({ data }) => {
        if (selectedSellerId) {
          setTableData([data]);
        } else {
          setTableData(data);
        }
      })
      .catch(err => setToastList(t => [...t, getErrorToasts(err, keycloak)]))
      .finally(() => setLoadingTableData(false));
  };

  const handleSubmit = e => {
    e.preventDefault();

    switch (selectedOption) {
      case 'Histórico de CPP e/ou Frete':
        handleHistory();
        break;
      case 'Alterar CPP e/ou Frete': {
        setSelectedSellerIdError('');
        setCppError('');
        setFreightError('');

        let hasError = false;
        if (!selectedSellerId) {
          setSelectedSellerIdError('Selecione um parceiro para continuar');
          hasError = true;
        }
        if (!cpp) {
          hasError = true;
          setCppError('Digite o frete para continuar');
        }
        if (!freight) {
          setFreightError('Digite o Frete para continuar');
          hasError = true;
        }
        if (hasError) {
          setLoadingTableData(false);
          return;
        }

        setShowConfirmModal(true);
        break;
      }
      case 'Consulta de CPP e/ou Frete':
        handleConsult();
        break;
      default:
    }
  };

  return (
    <>
      <PricingCppFormComponent>
        <PricingCppFormComponent.Group>
          <PricingCppFormComponent.Control
            className="form-select"
            as="select"
            value={selectedOption}
            onChange={handleChangeOption}
          >
            <option>Histórico de CPP e/ou Frete</option>
            <option>Alterar CPP e/ou Frete</option>
            <option>Consulta de CPP e/ou Frete</option>
          </PricingCppFormComponent.Control>
        </PricingCppFormComponent.Group>

        <PricingCppFormComponent.Group>
          <div>
            <PricingCppFormComponent.Control
              className="form-select"
              as="select"
              value={selectedSellerId || 'seller'}
              onChange={handleChangeSeller}
              disabled={sellers.length <= 0}
            >
              <option
                value="seller"
                disabled={selectedOption !== 'Consulta de CPP e/ou Frete'}
              >
                Parceiro
              </option>
              {sellers.map(({ name, id }) => (
                <option key={`seller_${id}`} value={id}>
                  {name}
                </option>
              ))}
            </PricingCppFormComponent.Control>
          </div>
          <span className="invalid-text">{selectedSellerIdError}</span>
        </PricingCppFormComponent.Group>

        {selectedOption === 'Alterar CPP e/ou Frete' && (
          <>
            <PricingCppFormComponent.Group>
              <div>
                <PricingCppFormComponent.Label>
                  CPP:
                </PricingCppFormComponent.Label>
                <PricingCppFormComponent.Control
                  value={cpp}
                  type="number"
                  onChange={e => setCpp(e.target.value)}
                />
              </div>
              <span className="invalid-text">{cppError}</span>
            </PricingCppFormComponent.Group>
            <PricingCppFormComponent.Group>
              <div>
                <PricingCppFormComponent.Label>
                  Frete:
                </PricingCppFormComponent.Label>
                <PricingCppFormComponent.Control
                  value={freight}
                  type="number"
                  onChange={e => setFreight(e.target.value)}
                />
              </div>
              <span className="invalid-text">{freightError}</span>
            </PricingCppFormComponent.Group>
          </>
        )}

        <Button
          onClick={handleSubmit}
          typeOfButton="primary"
          disabled={loadingTableData}
        >
          {selectedOption === 'Alterar CPP e/ou Frete'
            ? 'Alterar'
            : 'Consultar'}
        </Button>
      </PricingCppFormComponent>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmação </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '3rem' }}>
          <span className="modal-text">
            Deseja realmente alterar CPP e/ou Frete.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            typeOfButton="secondary"
            onClick={e => {
              e.preventDefault();
              handleChange();
            }}
          >
            Sim
          </Button>
          <Button
            typeOfButton="secondary"
            onClick={e => {
              e.preventDefault();
              setShowConfirmModal(false);
            }}
          >
            Não
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PricingCppForm;
