import React, { useEffect, useState } from 'react';

import Button from '../../Button';

import { StyledForm } from './styles';

export default function BlockContactFilter({ currentFilter, handleFilter }) {
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [contact, setContact] = useState('');

  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleEndDate = e => {
    setEndDate(e.target.value);
  };

  const handleContact = e => {
    setContact(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.contact;

    setStartDate('');
    setEndDate('');
    setContact('');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.contact;

    if (startDate) filter.startDate = startDate;
    if (endDate) filter.endDate = endDate;
    if (contact) filter.contact = contact;
    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid = startDate || endDate || contact;
    return isValid;
  };

  const isClearFilterValid = () => {
    const isValid =
      currentFilter.pageNumber > 0 || startDate || endDate || contact;
    return isValid;
  };

  useEffect(() => {
    setStartDate('');
    setEndDate('');
    setContact('');
  }, [currentFilter]);

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Período:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleStartDate}
            type="date"
            value={startDate}
          />
          <StyledForm.Control
            onChange={handleEndDate}
            type="date"
            value={endDate}
          />
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Telefone/E-mail: </StyledForm.Label>
            <StyledForm.Control
              onChange={handleContact}
              type="text"
              value={contact}
            />
          </StyledForm.Group>
        </div>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button
          onClick={clearFilter}
          type="button"
          disabled={!isClearFilterValid()}
          className="btn-clear-filter"
        >
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
