import React from 'react';

import { StyledStatus } from './styles';

export default function CashbackStatus({ status }) {
  return (
    <StyledStatus>
      {status === 'PROCESSED_FAILED' && (
        <div className="status barred">Erro no processamento</div>
      )}
      {status === 'PROCESSING_PIX' && (
        <div className="status review">Aguardando pagamento PIX</div>
      )}
      {status === 'SUCCESS_PIX' && (
        <div className="status authorize">Creditado via PIX</div>
      )}
    </StyledStatus>
  );
}
