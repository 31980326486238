import { Switch, Route } from 'react-router-dom';
import React from 'react';

import Prevention from '../pages/Prevention';
import OnboardingConta from '../pages/OnboardingConta';
import Coupons from '../pages/Coupons';
import AirCampaign from '../pages/AirCampaign';
import IncentiveEnginePage from '../pages/TransactionsRelease';
import TransactionsPmaPage from '../pages/TransactionsPma';
import PartnersParityPage from '../pages/PartnersParity';
import ParityPartnerPage from '../pages/ParityPartner';
import CampaignPartnerPage from '../pages/CreateCampaignPartner';
import OrdersPmaPage from '../pages/OrdersPma';
import OrdersPage from '../pages/Orders';
import BlacklistPage from '../pages/Blacklist';
import ManualOrders from '../pages/ManualOrders';
import AntifraudRulesPage from '../pages/AntifraudRules';
import CashbackAwin from '../pages/CashbackAwin';
import ReleaseCashback from '../pages/ReleaseCashback';
import ManualOrdersTransfer from '../pages/ManualOrdersTransfer';
import PricingCpp from '../pages/PricingCpp';
import BlockClient from '../pages/BlockClient';
import UnblockClient from '../pages/UnblockClient';
import MaximumPointLimit from '../pages/MaximumPointLimit';
import Tracking from '../pages/Tracking';
import CampaignHistory from '../pages/CampaignHistory';
import BlockContact from '../pages/BlockContact';
import CppRules from '../pages/CppRule';
import CashPlusPointRule from '../pages/CashPlusPointRule';

export default function AppRouter({ userPermissions }) {
  return (
    <>
      <Switch>
        <Route
          path="/prevention"
          exact
          component={() => <Prevention userPermissions={userPermissions} />}
        />
        <Route
          path="/onboarding-conta"
          exact
          component={() => (
            <OnboardingConta userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/block"
          exact
          component={() => <BlockClient userPermissions={userPermissions} />}
        />
        <Route
          path="/unblock"
          exact
          component={() => <UnblockClient userPermissions={userPermissions} />}
        />
        <Route
          path="/coupons"
          exact
          component={() => <Coupons userPermissions={userPermissions} />}
        />
        <Route
          path="/cpp-rules"
          exact
          component={() => <CppRules userPermissions={userPermissions} />}
        />
        <Route
          path="/cash-plus-point-rules"
          exact
          component={() => (
            <CashPlusPointRule userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/manual-orders-transfer"
          exact
          component={() => (
            <ManualOrdersTransfer userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/maximum-points-limit"
          exact
          component={() => (
            <MaximumPointLimit userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/tracking"
          exact
          component={() => <Tracking userPermissions={userPermissions} />}
        />
        <Route
          path="/air-campaign"
          exact
          component={() => <AirCampaign userPermissions={userPermissions} />}
        />
        <Route
          path="/orders"
          exact
          component={() => <OrdersPage userPermissions={userPermissions} />}
        />
        <Route
          path="/antifraud-rules"
          exact
          component={() => (
            <AntifraudRulesPage userPermissions={userPermissions} />
          )}
          userPermissions={userPermissions}
        />
        <Route
          path="/blocklist"
          exact
          component={() => <BlacklistPage userPermissions={userPermissions} />}
        />
        <Route
          path="/blockcontact"
          exact
          component={() => <BlockContact userPermissions={userPermissions} />}
        />
        <Route
          path="/transactions-to-release"
          exact
          component={() => (
            <IncentiveEnginePage userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/transactions-pma"
          exact
          component={() => (
            <TransactionsPmaPage userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/orders-pma"
          exact
          component={() => <OrdersPmaPage userPermissions={userPermissions} />}
        />
        <Route
          path="/manual-orders"
          exact
          component={() => <ManualOrders userPermissions={userPermissions} />}
        />
        <Route
          path="/cashback-awin"
          exact
          component={() => <CashbackAwin userPermissions={userPermissions} />}
        />
        <Route
          path="/pricing-cpp"
          exact
          component={() => <PricingCpp userPermissions={userPermissions} />}
        />
        <Route
          path="/release-cashback"
          exact
          component={() => (
            <ReleaseCashback userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/partners-parity"
          exact
          component={() => (
            <PartnersParityPage userPermissions={userPermissions} />
          )}
        />
        <Route
          path="/campaign-history/:partnerId"
          exact
          component={() => (
            <CampaignHistory userPermissions={userPermissions} />
          )}
        />

        <Route
          path="/partners-parity/:partnerId"
          exact
          component={() => (
            <ParityPartnerPage userPermissions={userPermissions} />
          )}
        />

        <Route
          path="/campaign/:partnerId"
          exact
          component={() => (
            <CampaignPartnerPage userPermissions={userPermissions} />
          )}
        />

        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.SEE_MAIN_MENU',
          userPermissions.userGroups,
        ) ||
        userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.ORDERS.SEE_MAIN_MENU',
          userPermissions.userGroups,
        ) ? (
          <Route
            to="*"
            component={() => (
              <AntifraudRulesPage userPermissions={userPermissions} />
            )}
          />
        ) : (
          <Route
            to="*"
            component={() => <ManualOrders userPermissions={userPermissions} />}
          />
        )}
      </Switch>
    </>
  );
}
