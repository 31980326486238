import styled from 'styled-components';

import { Form as FormBootstrap } from 'react-bootstrap';
import { Modal as ModalBootstrap } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  font-family: 'Poppins';

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 500px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;
    font-family: 'Poppins';

    .close {
      color: #cc0000;
      font-weight: 100;
      font-size: 38px;
    }
    .modal-title {
      font-size: 1.3rem;
      font-weight: 800;
      padding-top: 10px;
      font-family: 'Poppins';
    }
  }

  .modal-body {
    font-family: 'Poppins';
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 300;
      }
    }
  }

  .type-pix {
    display: inline-flex;
    gap: 10px;
  }
`;

/* 
  .collapse {
    overflow: hidden;
  }

  .collapse:not(.show) {
    max-height: 0px;
    transition: all 0.3s ease;
  }
  .collapse.show {
    max-height: 100%;
    transition: all 0.3s ease;
  }
*/

export const Form = styled(FormBootstrap)`
  margin-top: 6px;
  font-family: 'Poppins';

  label {
    font-family: 'Poppins';
    font-size: 0.875rem;
  }

  textarea {
    min-height: 120px;
    font-family: 'Poppins';
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 1px;
  }

  .title {
    padding-left: 30px;
    display: inline-flex;
    font-family: 'Poppins';
    font-size: 0.875rem;
    font-weight: 500;
    padding-left: 10px;
  }

  .outside-accordion {
    .accordion {
      border: 1px solid #707070;
    }
    .accordion:first-child {
      border-radius: 10px 10px 0px 0px;
    }
    .accordion:only-child {
      border-radius: 10px 10px 10px 10px !important;
    }
    .accordion:not(:first-child, :last-child) {
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
    }
    .accordion:last-child {
      border-radius: 0px 0px 10px 10px;
    }
  }
  .body-accordion {
    font-family: 'Poppins';
    font-size: 0.875rem;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .status {
    width: fit-content;
    padding: 4px 6px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-left: 15px;

    &.inactive {
      color: var(--gray-200);
      background: var(--gray-500);
    }

    &.barred {
      border: 1px solid red;
      border-radius: 10px;
      color: var(--suportDarkError);
      background: var(--suportLightError);
    }

    &.review {
      border: 1px solid #ffb100;
      border-radius: 10px;
      color: var(--suportDarkWarning);
      background: var(--suportLightWarning);
    }

    &.authorize {
      border: 1px solid green;
      border-radius: 10px;
      color: var(--suportDarkSuccess);
      background: var(--suportLightSuccess);
    }
  }

  .radio__text {
    cursor: pointer;
    color: #222222;
    font-size: 16px;
    font-family: 'Poppins';
  }
`;

export const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;
    font-family: 'Poppins';

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
      &:active{
        .modal{
          background: #0000009e;
      }
    }
`;
