import styled, { css } from 'styled-components';

export const Container = styled.tbody`
  td {
    font-family: 'Roboto';
    font-size: 14px;
    color: var(--gray-100);
    line-height: 16px;

    &.emphasis {
      color: var(--gray-200);
      font-weight: 700;
    }

    .status {
      width: fit-content;
      padding: 4px 6px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;

      &.inactive {
        color: var(--gray-200);
        background: var(--gray-500);
      }

      &.barred {
        color: var(--suportDarkError);
        background: var(--suportLightError);
      }

      &.review {
        color: var(--suportDarkWarning);
        background: var(--suportLightWarning);
      }

      &.authorize {
        color: var(--suportDarkSuccess);
        background: var(--suportLightSuccess);
      }
    }
  }
  tr:hover {
    background-color: #deedf2;
  }
`;
