import React, { useState } from 'react';
import { Container } from './styles';
import MaximumPointLimitForm from '../../components/MaximumPointLimit/MaximumPointLimitForm';
import ToastCustom from '../../components/Toast';
import { populateToastMessagesGlobal } from '../../helpers/GlobalFunctions';

export default function MaximumPointLimit({ userPermissions }) {
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.LIMIT_BUY_POINTS.READ',
        userPermissions.userGroups,
      ) ? (
        <>
          <h2>Limite para compra de pontos</h2>
          <MaximumPointLimitForm
            userPermissions={userPermissions}
            populateToastMessages={populateToastMessages}
          />
        </>
      ) : null}
    </Container>
  );
}
