import styled from 'styled-components';

import { Form as FormBootstrap } from 'react-bootstrap';
import { Modal as ModalBootstrap } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  .modal-content {
    border-radius: 1rem !important;
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 40%;
      min-width: 740px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
 
  .modal-header {
    border: 0;
    padding: 2rem 1rem 0 2rem;

    .close {
      color: #cc0000;
      font-weight: 100;
      font-size: 38px;
      margin-right: 10px;
    }

    .modal-title {
      font-size: 1.3rem;
      font-weight: 800;
    }
  }

  .modal-body {
    padding: 1rem 3rem 3rem 3rem;
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 500;
      }
    }
  }

  .type-pix {
    display: inline-flex;
    gap: 10px;
  }
`;

export const Form = styled(FormBootstrap)`
  margin-top: 4px;

  label {
    font-weight: 600;
  }

  textarea {
    min-height: 120px;
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 12px;
  }
`;

export const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
      &:active{
        .modal{
          background: #0000009e;
      }
    }
`;
