import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../components/Button';
import ToastCustom from '../../components/Toast';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import BlockContactTable from '../../components/BlockContactPage/BlockContactTable';
import ModalAddProduct from '../../components/BlockContactPage/ModalAddProduct';
import BlockContactFilter from '../../components/BlockContactPage/BlockContactFilter';

import {
  handleGetRequest,
  initialPaginationData,
  initialHeadersData,
} from '../../services/GenericMethods';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

import { Container } from './styles';

export default function BlockContact({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [isOpenModalAddProduct, setIsOpenModalAddProduct] = useState(false);
  const FULL_URL = window.SERVICES_DOMAIN + window.BLOCK_CONTACT_GET_URL;
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const getContacts = (payload, type = '') => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CONTACT_BLOCK_LIST.READ',
        userPermissions.userGroups,
      )
    ) {
      let reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        params: {
          page: options.params.pageNumber,
          size: options.params.pageCount,
        },
      };
      if (type === 'filter') reqOptions = payload;
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setContacts(content);

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setContacts([]);
          }

          console.warn(`Response listContacts = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listContacts request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleFilter = reqOptions => {
    options.params = reqOptions;
    setCurrentFilter(reqOptions);
    getContacts(options, 'filter');
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getContacts(options);
  };

  const handleShowMdlAddProduct = () => setIsOpenModalAddProduct(true);
  const handleCloseMdlAddProduct = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }

    setIsOpenModalAddProduct(false);
  };

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  useEffect(() => {
    getContacts(options);
    setCurrentFilter(options.params);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <header>
        <h2>Contatos bloqueados</h2>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.CONTACT_BLOCK_LIST.CREATE',
          userPermissions.userGroups,
        ) ? (
          <Button typeOfButton="primary" onClick={handleShowMdlAddProduct}>
            Cadastrar Contatos
          </Button>
        ) : null}
      </header>

      <BlockContactFilter
        currentFilter={currentFilter}
        handleFilter={handleFilter}
      />

      {userPermissions.checkUiPermissionsFn(
        'ESFERA.CONTACT_BLOCK_LIST.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {contacts.length > 0 ? (
            <BlockContactTable
              contacts={contacts}
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          onClickFnPage={handlePageChange}
        />
      ) : null}

      <ModalAddProduct
        isOpenModal={isOpenModalAddProduct}
        handleCloseModal={handleCloseMdlAddProduct}
        userPermissions={userPermissions}
      />
    </Container>
  );
}
