import React from 'react';

import { StyledTable } from './styles';
import CashbackItem from '../CashbackItem';

import CashbackExport from '../CashbackExport';

export default function CashbackTable({ cashbacks }) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Status</th>
          <th className="content">Dt Liberação Cashback</th>
          <th>Tipo</th>
          <th className="content">Dt Transferência PIX</th>
          <th>Operador</th>
          <th>CPF/CNPJ</th>
          <th>Cliente</th>
          <th>Parceiro</th>
          <th className="content">Dt Compra</th>
          <th className="content">Vl Compra</th>
          <th className="content">Vl Cashback</th>
          <th>
            <CashbackExport cashbacks={cashbacks} />
          </th>
        </tr>
      </thead>

      <tbody>
        {cashbacks.map(cashback => (
          <CashbackItem cashback={cashback} key={cashback.id} />
        ))}
      </tbody>
    </StyledTable>
  );
}
