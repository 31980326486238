import styled from 'styled-components';

import { Form } from 'react-bootstrap';

export const StyledForm = styled(Form)`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Poppins;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    font-family: Poppins;
  }

  .form-content {
    width: 755px;
    height: 285px;
    display: flex;
    flex-direction: column;
    font-family: Poppins;
  }

  .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 10px;
    font-family: Poppins;

    @media (max-width: 573px) {
      width: 100%;
      margin-right: 0;
    }

    .form-group period-inputs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 10px;
      font-family: Poppins;
    }

    select {
      width: 180px;
      font-size: 0.875rem;
      font-family: Poppins;
    }

    > label {
      margin: 0 10px 0 0;
      font-size: 0.875rem;
      width: 210px;
      font-family: Poppins;

      @media (max-width: 600px) {
        width: 75px;
      }
    }

    input[type='date'] {
      width: 180px;
      margin-right: 10px;
      font-size: 0.78rem;
      font-family: Poppins;

      @media (max-width: 473px) {
        flex: 1;
        margin-right: 0;
        &:nth-child(3) {
          margin-top: 10px;
          margin-left: 75px;
        }
      }
    }

    input[type='text'] {
      width: 180px;
      font-size: 0.775rem;
      font-family: Poppins;

      @media (max-width: 473px) {
        width: 100%;
        flex: 1;
      }
    }
  }

  .end-date {
    margin-left: 10px;
    font-family: Poppins;
  }

  .inputs-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-family: Poppins;
  }

  .form-actions {
    font-family: Poppins;
    margin-left: 130px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 265px;
    min-width: 150px;

    @media (max-width: 1080px) {
      margin-left: 0;
      width: 100%;
    }

    @media (max-width: 573px) {
      max-width: none;
    }

    .btn-clear-filter {
      font-family: Poppins;
      border: 0;
      background: transparent;
      outline: 0;
      color: var(--gray-200);
      margin-top: 20px;
      text-decoration: underline;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    button[type='submit'] {
      font-family: Poppins;
      width: 100%;
    }
  }
`;
