import React, { useState, useEffect } from 'react';
import Button from '../../Button';
import { maskCNPJ, maskCPF } from '../../../helpers/GlobalFunctions';

import { StyledForm } from './styles';

export default function CashbackFilter({ applyFilter, partnerNames }) {
  const statusCode = [
    {
      label: 'Todos',
      value: 'ALL',
    },
    {
      label: 'Falha ao Processar Cashback',
      value: 'FAILED_CASHBACK_PMA',
    },
    {
      label: 'Aguardando pagamento via PIX',
      value: 'PROCESSING_PIX',
    },
    {
      label: 'Creditado via PIX',
      value: 'SUCCESS_PIX',
    },
  ];
  const [partners, setPartners] = useState(partnerNames || []);

  useEffect(() => {
    setPartners(partnerNames || []);
  }, [partnerNames]);

  const [filter, setFilter] = useState({
    cashbackReleaseStartDate: '',
    cashbackReleaseEndDate: '',
    purchaseStartDate: '',
    purchaseEndDate: '',
    paymentStartDate: '',
    paymentEndDate: '',
    partner: '',
    documentId: '',
    status: '',
  });

  const handlerFilter = event => {
    const { value, id } = event.target;
    setFilter(prevState => Object.freeze({ ...prevState, [id]: value }));
  };

  const clearFilter = () => {
    const newFilter = {
      cashbackReleaseStartDate: '',
      cashbackReleaseEndDate: '',
      purchaseStartDate: '',
      purchaseEndDate: '',
      paymentStartDate: '',
      paymentEndDate: '',
      partner: '',
      documentId: '',
      status: '',
    };

    setFilter(newFilter);
    applyFilter(newFilter);
  };

  const updateFilter = e => {
    e.preventDefault();
    applyFilter({
      cashbackReleaseStartDate: filter.cashbackReleaseStartDate,
      cashbackReleaseEndDate: filter.cashbackReleaseEndDate,
      purchaseStartDate: filter.purchaseStartDate,
      purchaseEndDate: filter.purchaseEndDate,
      paymentStartDate: filter.paymentStartDate,
      paymentEndDate: filter.paymentEndDate,
      partner: filter.partner,
      documentId: filter.documentId,
      status: filter.status,
    });
  };

  const handlerCPFCNPJClient = value => {
    if (value.length <= 14) return maskCPF(value);
    if (value.length <= 18) return maskCNPJ(value);
    return value;
  };

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Liberação do Cashback de </StyledForm.Label>
          <StyledForm.Control
            id="cashbackReleaseStartDate"
            onChange={handlerFilter}
            type="date"
            value={filter.cashbackReleaseStartDate}
          />
          até
          <StyledForm.Control
            id="cashbackReleaseEndDate"
            onChange={handlerFilter}
            type="date"
            value={filter.cashbackReleaseEndDate}
            className="end-date"
          />
        </StyledForm.Group>

        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Data da Compra </StyledForm.Label>
          <StyledForm.Control
            id="purchaseStartDate"
            onChange={handlerFilter}
            type="date"
            value={filter.purchaseStartDate}
          />
          até
          <StyledForm.Control
            id="purchaseEndDate"
            onChange={handlerFilter}
            type="date"
            value={filter.purchaseEndDate}
            className="end-date"
          />
        </StyledForm.Group>

        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Transferido via PIX de</StyledForm.Label>
          <StyledForm.Control
            id="paymentStartDate"
            onChange={handlerFilter}
            type="date"
            value={filter.paymentStartDate}
          />
          até
          <StyledForm.Control
            id="paymentEndDate"
            onChange={handlerFilter}
            type="date"
            value={filter.paymentEndDate}
            className="end-date"
          />
        </StyledForm.Group>

        <StyledForm.Group>
          <StyledForm.Label>Parceiro:</StyledForm.Label>
          <StyledForm.Control
            as="select"
            id="partner"
            value={filter.partner}
            onChange={handlerFilter}
          >
            <option disabled value="">
              Filtre por um parceiro
            </option>
            {partners.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>CPF/CNPJ Cliente:</StyledForm.Label>
            <StyledForm.Control
              id="documentId"
              onChange={event => {
                document.getElementById('documentId').value =
                  handlerCPFCNPJClient(event.target.value);
                if (event.target.value.length > 18) return false;
                return handlerFilter(event);
              }}
              type="text"
              value={filter.documentId}
            />
          </StyledForm.Group>
        </div>

        <StyledForm.Group>
          <StyledForm.Label>Status: </StyledForm.Label>
          <StyledForm.Control
            as="select"
            value={filter.status}
            id="status"
            onChange={handlerFilter}
          >
            <option disabled value="">
              Filtre por um status
            </option>
            {statusCode.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>
      </div>

      <div className="form-actions">
        <Button type="submit" typeOfButton="primary" onClick={updateFilter}>
          Aplicar Filtros
        </Button>
        <button
          onClick={clearFilter}
          type="button"
          className="btn-clear-filter"
        >
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
