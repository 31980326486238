import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Content from '../../components/Content';
import * as S from './styles';
import ToastCustom from '../../components/Toast';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import AirCampaignTable from '../../components/AirCampaignPage/AirCampaignTable';
import EditCampaign from '../../components/AirCampaignPage/EditCampaign';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../services/GenericMethods';

import Button from '../../components/Button';

const headTableDataAirCampaign = [
  {
    title: 'Parceiro',
    hasIcoSort: false,
  },
  {
    title: 'Campanha',
    hasIcoSort: false,
  },
  {
    title: 'Usuário',
    hasIcoSort: true,
  },
  {
    title: 'Data inicial',
    hasIcoSort: false,
  },
  {
    title: 'Data final',
    hasIcoSort: false,
  },
  {
    title: 'Status',
    hasIcoSort: false,
  },
];

export default function AirCampaign({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL}/current`;
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [isModifiedAirCampaign, setIsModifiedAirCampaign] = useState(false);
  const [isOpenModalCreateCoupon, setIsOpenModalCreateCoupon] = useState(false);
  const [bodyTableDataAirCampaign, setBodyTableDataAirCampaign] = useState();
  const handleShowMdlCreateCoupon = () => setIsOpenModalCreateCoupon(true);
  const handleCloseMdlCreateCoupon = () => setIsOpenModalCreateCoupon(false);

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };
  const getCampaigns = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.length > 0)
            setBodyTableDataAirCampaign(response.data);
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    getCampaigns();
  }, [isModifiedAirCampaign]);

  return (
    <>
      <Content>
        <S.Container>
          <S.AirCampaignHeaderContentStyle>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <div className="contentmain__header__text">Marketing</div>
                <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
                <div className="contentmain__header__text contentmain__header__text__active">
                  Campanhas Aéreas
                </div>
              </div>

              {userPermissions.checkUiPermissionsFn(
                'ESFERA.MILES_CAMPAIGN.CREATE',
                userPermissions.userGroups,
              ) ? (
                <Button
                  typeOfButton="primary"
                  onClick={handleShowMdlCreateCoupon}
                >
                  Cadastrar campanha
                </Button>
              ) : null}
            </div>
          </S.AirCampaignHeaderContentStyle>
          <S.AirCampaignHeaderText>
            Página dedicada para a criação de campanhas com os parceiros de
            aéreas, quando o objetivo for bonificar o cliente Clube Esfera, de
            uma forma diferenciada.
          </S.AirCampaignHeaderText>
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.MILES_CAMPAIGN.READ',
            userPermissions.userGroups,
          )
            ? bodyTableDataAirCampaign && (
                <AirCampaignTable
                  headTableData={headTableDataAirCampaign}
                  bodyTableData={bodyTableDataAirCampaign}
                  userPermissions={userPermissions}
                  isModifiedAirCampaign={isModifiedAirCampaign}
                  setIsModifiedAirCampaign={setIsModifiedAirCampaign}
                  populateToastMessages={populateToastMessages}
                />
              )
            : null}
          <EditCampaign
            isOpenModal={isOpenModalCreateCoupon}
            handleCloseModal={handleCloseMdlCreateCoupon}
            userPermissions={userPermissions}
            setIsModifiedAirCampaign={setIsModifiedAirCampaign}
            populateToastMessages={populateToastMessages}
          />

          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
    </>
  );
}
