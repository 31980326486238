import React from 'react';
import { SRadioButton } from './styles';

export default function RadioButton({
  id,
  name,
  value,
  checked,
  onChange,
  children,
  disabled = false,
}) {
  return (
    <SRadioButton>
      <label htmlFor={id}>
        <input
          className="radio__button"
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span
          className={`radio__checkmark ${
            disabled ? 'radio__checkmark_disabled' : ''
          }`}
        />
        {children && <span className="radio__text">{children}</span>}
      </label>
    </SRadioButton>
  );
}
