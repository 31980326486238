import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { Container } from './styles';
import CashbackFilter from '../../components/ReleaseCashbackPage/CashbackFilter';
import CashbackTable from '../../components/ReleaseCashbackPage/CashbackTable';
import Pag from '../../components/ReleaseCashbackPage/Pag';

import {
  formatDate,
  toCurrencyBRL,
  maskCPF,
  maskCNPJ,
} from '../../helpers/GlobalFunctions';

import {
  handleGetRequest,
  initialHeadersData,
} from '../../services/GenericMethods';

export default function ReleaseCashback({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [allCashbacks, setAllCashbacks] = useState([]);
  const [cashbacks, setCashbacks] = useState([]);

  const [renderCashbacks, setRenderCashbacks] = useState([]);
  const [partnerNames, setPartnerNames] = useState([]);

  const [paginate, setPaginate] = useState({
    perPage: 10,
    currentPage: 0,
    totalPages: 0,
  });

  useEffect(async () => {
    try {
      const { data } = await handleGetRequest(
        `${window.SERVICES_DOMAIN}/global/v1/admin-painel/cashback-release/filter`,
        {
          headers: {
            ...initialHeadersData(keycloak.token),
            apikey: '14102019',
          },
        },
      );
      const allCash = data.content.map(e => {
        return {
          id: e.id,
          createDate: e.createDate
            ? formatDate(e.createDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
            : '-',
          updateDate: e.updateDate
            ? formatDate(e.updateDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
            : '-',
          documentId:
            e.documentId.length === 14
              ? maskCNPJ(e.documentId)
              : maskCPF(e.documentId),
          clientName: e.clientName,
          partnerOrderId: e.partnerOrderId,
          partnerName: e.partnerName,
          orderId: e.orderId,
          status: e.status,
          cashbackValue: toCurrencyBRL(e.cashbackValue),
          productValue: toCurrencyBRL(e.productValue),
          type: e.type,
          releaseDate: e.releaseDate
            ? formatDate(e.releaseDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
            : '-',
          paymentDate: e.paymentDate
            ? formatDate(e.paymentDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
            : '-',
          lastOperator: e.lastOperator,
          isProcessed: e.isProcessed,
        };
      });

      setAllCashbacks(allCash);
      setCashbacks(allCash);
      setRenderCashbacks(allCash.slice(0, 10));
      setPaginate({
        ...paginate,
        totalPages: Math.ceil(allCash.length / 10),
      });

      setPartnerNames([
        ...new Set(allCash.map(cashback => cashback.partnerName)),
      ]);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handlePageChange = async page => {
    const offset = page * 10;
    setPaginate({
      ...paginate,
      totalPages: Math.ceil(cashbacks.length / 10),
      currentPage: page,
    });
    setRenderCashbacks(cashbacks.slice(offset, offset + 10));
  };
  const handleFilter = ({
    cashbackReleaseStartDate,
    cashbackReleaseEndDate,
    purchaseStartDate,
    purchaseEndDate,
    paymentStartDate,
    paymentEndDate,
    partner,
    documentId,
    status,
  }) => {
    const newData = allCashbacks.filter(currentCashback => {
      return (
        (cashbackReleaseStartDate !== '' && cashbackReleaseEndDate !== ''
          ? moment(currentCashback.releaseDate, 'DD/MM/YYYY').isBetween(
              moment(cashbackReleaseStartDate, 'YYYY-MM-DD'),
              moment(cashbackReleaseEndDate, 'YYYY-MM-DD'),
              undefined,
              '[]',
            )
          : true) &&
        (purchaseStartDate !== '' && purchaseEndDate !== ''
          ? moment(currentCashback.createDate, 'DD/MM/YYYY').isBetween(
              moment(purchaseStartDate, 'YYYY-MM-DD'),
              moment(purchaseEndDate, 'YYYY-MM-DD'),
              undefined,
              '[]',
            )
          : true) &&
        (paymentStartDate !== '' && paymentEndDate !== ''
          ? moment(currentCashback.paymentDate, 'DD/MM/YYYY').isBetween(
              moment(paymentStartDate, 'YYYY-MM-DD'),
              moment(paymentEndDate, 'YYYY-MM-DD'),
              undefined,
              '[]',
            )
          : true) &&
        (partner !== '' ? currentCashback.partnerName === partner : true) &&
        (documentId !== ''
          ? currentCashback.documentId === documentId
          : true) &&
        (status !== '' && status !== 'ALL'
          ? currentCashback.status === status
          : true)
      );
    });
    setCashbacks(newData);
  };

  useEffect(() => {
    handlePageChange(0);
  }, [cashbacks]);

  return (
    <Container>
      <div>
        <h2>Liberar Cashbacks</h2>
        <CashbackFilter
          applyFilter={handleFilter}
          partnerNames={partnerNames}
        />
      </div>
      <div>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.RELEASE_CASHBACK.READ',
          userPermissions.userGroups,
        ) ? (
          <section>
            {cashbacks.length > 0 ? (
              <div>
                <CashbackTable cashbacks={renderCashbacks} />
              </div>
            ) : (
              <div>Nenhum resultado encontrado para esta pesquisa</div>
            )}
          </section>
        ) : null}
        {paginate.totalPages > 0 ? (
          <Pag
            currentPage={paginate.currentPage}
            totalPages={paginate.totalPages}
            onClickFnPage={handlePageChange}
          />
        ) : null}
      </div>
    </Container>
  );
}
