import React from 'react';
import { StyledTable } from '../styles';

const CppTableRow = ({ seller, showAlterationDates }) => {
  let date;
  if (showAlterationDates) {
    date = new Date(seller.createdAt)
      .toLocaleString('pt')
      .replace(',', '')
      .split(':');
    date.pop();
    date = date.join(':');
  }

  return (
    <tr>
      <td>{seller.seller}</td>
      <td>{seller.cpp}</td>
      <td>{seller.freight}</td>
      {showAlterationDates && (
        <>
          <td>{seller.revision}</td>
          <td>{date}</td>
        </>
      )}
    </tr>
  );
};

const PricingCppTable = ({ data, showAlterationDates = false }) => (
  <StyledTable responsive>
    <thead>
      <tr>
        <th>Parceiro</th>
        <th>CPP</th>
        <th>Frete</th>
        {showAlterationDates && (
          <>
            <th>Alteração</th>
            <th>Início de vigência</th>
          </>
        )}
      </tr>
    </thead>

    <tbody>
      {data.map(seller => (
        <CppTableRow
          key={`seller_${seller.id}`}
          seller={seller}
          showAlterationDates={showAlterationDates}
        />
      ))}
    </tbody>
  </StyledTable>
);

export default PricingCppTable;
