import styled from 'styled-components';

import { Form as FormBootstrap } from 'react-bootstrap';
import { Modal as ModalBootstrap } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  font-family: 'Poppins';

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 500px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .close {
      color: #cc0000;
      font-weight: 100;
      font-size: 38px;
    }
    .modal-title {
      font-size: 1.3rem;
      font-weight: 800;
      padding-top: 10px;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 300;
      }
    }
  }

  .type-pix {
    display: inline-flex;
    gap: 10px;
  }
`;

export const Form = styled(FormBootstrap)`
  margin-top: 6px;

  label {
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  textarea {
    min-height: 120px;
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 1px;
  }
  .form-label {
    align-items: center;
    display: inline-flex;
  }
  .radio__text {
    cursor: pointer;
    color: #222222;
    font-size: 14px;
  }

  b {
    padding-right: 5px;
  }
  
 .radio__checkmark {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border: 2px solid #cc0000;
    border-radius: 100%;
    color: #cc0000;
    cursor: pointer;
  }

`;

export const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
      &:active{
        .modal{
          background: #0000009e;
      }
    }
`;
