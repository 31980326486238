import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  handleGetRequest,
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';
import { StyledSwitch } from '../../RuleItem/styles';
import { Container } from './styles';

export default function OnboardingConfiguration({
  userPermissions,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const [isSwitch, setIsSwitch] = useState('');
  const FULL_URL_POST = window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL;
  const FULL_URL_GET = `${
    window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL
  }/name/RedemptionEnabled`;

  const handleChangeSwitch = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ONBOARDING.RULES.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: 'RedemptionEnabled',
          value: {
            boolean_value: !isSwitch,
          },
          user: '',
        },
      };
      handlePostRequest(FULL_URL_POST, reqOptions)
        .then(() => {
          setIsSwitch(!isSwitch);
          populateToastMessages(
            'success',
            'Sucesso!',
            'Regra alterada com sucesso!',
          );
        })
        .catch(error => {
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'secundary',
          );
        });
    }
  };

  const getFlagRedemption = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ONBOARDING.RULES.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: 'RedemptionEnabled',
        },
      };
      handleGetRequest(FULL_URL_GET, reqOptions)
        .then(response => {
          if (response && response.data && response.data.value)
            setIsSwitch(response.data.value.boolean_value);
        })
        .catch(error => {
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    getFlagRedemption();
    return () => {
      setIsSwitch({});
    };
  }, []);

  return (
    <Container>
      <div className="contentmain__header">
        <div className="contentmain__header__text subtitle">
          Configuração Onboarding :
        </div>
      </div>
      <div className="text-flag">
        Permitir onboarding com Flag Redemption desabilitada
        {userPermissions.checkUiPermissionsFn(
          'ONBOARDING.RULES.READ',
          userPermissions.userGroups,
        ) ? (
          <StyledSwitch
            type="switch"
            id="switch1"
            onChange={() => {
              handleChangeSwitch();
            }}
            checked={isSwitch}
            value={isSwitch}
          />
        ) : null}
      </div>
    </Container>
  );
}
