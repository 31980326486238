import React from 'react';
import { CSVLink } from 'react-csv';

import iconCsv from '../../../assets/iconCSV.png';

import { StyledForm } from './styles';

export default function CashbackExport({ cashbacks }) {
  const headers = [
    { label: 'Status', key: 'status' },
    { label: 'Dt Liberação Cashback', key: 'releaseDate' },
    { label: 'Tipo', key: 'type' },
    { label: 'Dt Transferência PIX', key: 'paymentDate' },
    { label: 'Operador', key: 'lastOperator' },
    { label: 'CPF', key: 'documentId' },
    { label: 'Cliente', key: 'clientName' },
    { label: 'Parceiro', key: 'partnerName' },
    { label: 'Dt Compra', key: 'paymentDate' },
    { label: 'Vl Compra', key: 'productValue' },
    { label: 'Vl Cashback', key: 'cashbackValue' },
  ];

  return (
    <StyledForm>
      <CSVLink
        data={cashbacks}
        headers={headers}
        filename="cashback.csv"
        className="csv-link"
      >
        <img src={iconCsv} alt="exportar CSV" />
      </CSVLink>
    </StyledForm>
  );
}
