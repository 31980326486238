import moment from 'moment';

export const findProp = (obj, prop, defval) => {
  if (typeof defval == 'undefined') defval = null;
  prop = prop.split('.');
  for (var i = 0; i < prop.length; i++) {
    if (typeof obj[prop[i]] == 'undefined') return defval;
    obj = obj[prop[i]];
  }
  return obj;
};

export const populateToastMessagesGlobal = (status, title, message, queue) => {
  let appendToast;
  if (status === 'error') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#d9534f',
      icon: 'errorIcon',
    };
  } else if (status === 'success') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#5cb85c',
      icon: 'checkIcon',
    };
  } else if (status === 'warning') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#f0ad4e',
      icon: 'warningIcon',
    };
  }
  return appendToast;
};

export const formatErrorMessageToToast = (error, keycloak) => {
  let responseErrorMessage;
  if (error.response) {
    let errorMessage;
    if (error.response.data.messages) {
      errorMessage = error.response.data.messages.join('.\n');
    } else if (error.response.data.error) {
      errorMessage = error.response.data.error;
    } else if (
      typeof error.response.data !== 'object' &&
      !Array.isArray(error.response.data) &&
      error.response.data !== null
    ) {
      errorMessage = error.response.data;
    } else {
      errorMessage = 'A requisição feita não obteve uma resposta válida.';
    }
    responseErrorMessage = {
      status: error.response.status,
      messagePTBR: errorMessage,
      messageEN: error.response.data.messages,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    responseErrorMessage = {
      status: '50X',
      messagePTBR: 'A requisição feita não obteve resposta.',
      messageEN: 'The request was made but no response was received',
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    responseErrorMessage = {
      status: '5XX',
      messagePTBR: 'Erro indefinido, favor aguarde e tente novamente.',
      messageEN:
        'Something happened in setting up the request that triggered an Error',
    };
  }

  if (error?.response?.data?.errorCode === "ERR_003") {
    responseErrorMessage = {
      status: '50X',
      messagePTBR: 'O contato já existe na lista de bloqueio.',
      messageEN: 'Contact already exists in block list with ACTIVE status.'
    }
  } else if (error?.response?.data?.errorCode === "ERR_004") {
    responseErrorMessage = {
      status: '50X',
      messagePTBR: 'O contato já foi removido da lista de bloqueio.',
      messageEN: 'The contact has already been removed from the block list.'
    }
  } else if (error?.response?.data?.errorCode === "ERR_005") {
    responseErrorMessage = {
      status: '50X',
      messagePTBR: 'Um ou mais campos da requisição são inválidos. Tente novamente.',
      messageEN: 'One or more fields in the request are invalid. Please try again.'
    }
  }

  if (keycloak && responseErrorMessage.status === 401) handleLogout(keycloak);

  return responseErrorMessage;
};

const handleLogout = keycloak => {
  keycloak.logout();
};

export const formatCpfCnpj = doc =>
  doc.length <= 11
    ? doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    : doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
export const formatDate = (date, inputFormat, outputFormat) => {
  return moment(date, inputFormat).format(outputFormat);
};

export const toCurrencyBRL = value => {
  return value
    ? value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    : null;
};

export const maskCPF = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskCNPJ = value => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskPhone = value => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d)/, '+$1');
  value = value.replace(/(.{3})(\d)/, '$1($2');
  value = value.replace(/(.{6})(\d)/, '$1)$2');
  if (value.length == 12) {
    value = value.replace(/(.{1})$/, '-$1');
  } else if (value.length == 13) {
    value = value.replace(/(.{2})$/, '-$1');
  } else if (value.length == 14) {
    value = value.replace(/(.{3})$/, '-$1');
  } else if (value.length == 15) {
    value = value.replace(/(.{4})$/, '-$1');
  } else if (value.length > 15) {
    value = value.replace(/(.{4})$/, '-$1');
  }
  return value;
};

export const removeMaskCPF = value => {
  return value.match(/\d/g).join('');
};

export const isValidEmail = email => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const formatPhone = phone => {
  let value = phone.replace(/\D/g, '');
  if (phone.length > 10) {
    value = phone.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  } else if (phone.length > 6) {
    value = phone.replace(
      /^(\d{2})(\d{4})(\d{0,4}).*/,
      '($1) $2-$3',
    );
  } else if (phone.length > 2) {
    value = phone.replace(/^(\d{2})(\d{0,5})/, '($1) $2');
  } else if (phone.length > 0) {
    value = phone.replace(/^(\d{0,2})/, '($1');
  }
  return value
};
