import styled from 'styled-components';

export const StyledStatus = styled.div`
  font-family: 'Poppins';

  .status {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: 600;
    margin-left: 8px;

    &.inactive {
      color: var(--gray-200);
      background: var(--gray-500);
    }

    &.barred {
      border: 1px solid red;
      color: var(--suportDarkError);
      background: var(--suportLightError);
    }

    &.review {
      border: 1px solid --suportDarkWarning;
      color: var(--suportDarkWarning);
      background: var(--suportLightWarning);
    }

    &.authorize {
      border: 1px solid green;
      color: var(--suportDarkSuccess);
      background: var(--suportLightSuccess);
    }
  }
`;
