import React, { useState } from 'react';

import ToastCustom from '../../components/Toast';
import PricingCppTable from './components/PricingCppTable';
import { Container } from './styles';
import PricingCppForm from './components/PricingCppForm';

const PricingCpp = () => {
  const [selectedOption, setSelectedOption] = useState(
    'Histórico de CPP e/ou Frete',
  );

  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [toastList, setToastList] = useState([]);

  return (
    <>
      <Container>
        <h2>Configuração de Preços (Pontos) - CPP por Seller</h2>
        <hr />
        <PricingCppForm
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setToastList={setToastList}
          setTableData={setTableData}
          setLoadingTableData={setLoadingTableData}
        />
        <hr />

        {selectedOption !== 'Alterar CPP e/ou Frete' &&
          (tableData.length <= 0 ? (
            <div className="center-text-container">
              <span className="center-text">
                {loadingTableData
                  ? 'Carregando...'
                  : 'Nenhum resultado encontrado'}
              </span>
            </div>
          ) : (
            <PricingCppTable
              data={tableData}
              showAlterationDates={
                selectedOption === 'Histórico de CPP e/ou Frete'
              }
            />
          ))}
      </Container>

      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
    </>
  );
};

export default PricingCpp;
