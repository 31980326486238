import React from 'react';

import { StyledModal } from './styles';

export default function Modal({
  isOpenModal,
  handleCloseModal,
  headerTitle,
  children,
}) {
  return (
    <>
      <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
        <StyledModal.Header closeButton>
          <StyledModal.Title>{headerTitle}</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>{children}</StyledModal.Body>
      </StyledModal>
    </>
  );
}
