import styled from 'styled-components';

import { Table } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  font-size: 0.875rem;

  th {
    border-top: 0;
    vertical-align: center;
    cursor: pointer;
  }
`;
