import React from 'react';

import BlockContactItem from '../BlockContactItem';

import { StyledTable } from './styles';

export default function BlockContactTable({
  contacts,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Contato</th>
          <th>Tipo de Contato</th>
          <th>Data de Cadastro</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        {contacts.map(item => (
          <BlockContactItem
            item={item}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
