import styled from "styled-components";

import { Form } from 'react-bootstrap';

export const StyledForm = styled(Form)`
	margin-top: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media (max-width: 1080px) {
		flex-direction: column;
		align-items: flex-start;
	}

	label {
		margin-right: 0.5rem;
		margin-bottom: 1.0rem;
	}

	.form-content {
		display: flex;
		flex-direction: column;
	}

	.form-group {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-right: 10px;

		@media (max-width: 473px) {
			width: 100%;
			margin-right: 0;
		}

		select {
			width: 248px;
		}

		> label {
		margin: 0 10px 0 0;
		font-size: 0.875rem;
		width: 75px;

			@media (max-width: 600px) {
				width: 65px;
			}
		}

		input[type="text"] {
			width: 170px;
			font-size: 0.875rem;

			@media (max-width: 473px) {
				width: 100%;
				flex: 1;
			}
		}
	}

	.inputs-group {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}

	.invalid {
		border: 1px solid red;
	}

	.form-actions {
		margin-left: 120px;
		text-align: center;
		margin-bottom: 20px;
		display: flex;
		flex: 1;
		flex-direction: column;
		max-width: 265px;
		min-width: 150px;

		@media (max-width: 1080px) {
			margin-left: 0;
			width: 100%;
		}

		@media (max-width: 473px) {
			max-width: none;
		}

		.btn-clear-filter {
			border: 0;
			background: transparent;
			outline: 0;
			color: var(--gray-200);
			margin-top: 20px;
			text-decoration: underline;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.7;
			}
		}

		button[type="submit"] {
			width: 100%;
		}
	}
`;
