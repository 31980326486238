import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  handlePutRequest,
  handleGetRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { StyledTr, WrapperButton } from './styles';
import ToastCustom from '../../Toast';
import {
  populateToastMessagesGlobal,
  removeMaskCPF,
} from '../../../helpers/GlobalFunctions';
import ModalPix from '../ModalPix';
import ModalDetails from '../ModalDetails';

export default function CashbackItem({
  cashback,
  transaction,
  userPermissions,
  handleShowMessageAction,
}) {
  const { keycloak } = useKeycloak();

  const [isOpenModalCashback, setIsOpenModalCashback] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [modalDetailData, setModalDetailData] = useState([]);

  const handleCloseModalDetail = () => {
    setModalDetailData([]);
    setModalDetailOpen(false);
  };

  const handleCloseMdlCashback = (status, title, message) => {
    if (status && title && message) {
      handleShowMessageAction(status, title, message);
    }
    setIsOpenModalCashback(false);
  };

  // IMPORTANT: Por definição de negócios as transações são feitas somente via CPF_CNPJ
  const confirmTransaction = async () => {
    try {
      await handlePutRequest(
        `${window.SERVICES_DOMAIN}/global/v1/admin-painel/cashback-release/process/pix/${cashback.id}`,
        {
          headers: initialHeadersData(keycloak.token),
          params: {
            pixType: 'CPF_CNPJ',
            pixKey: removeMaskCPF(cashback.documentId),
          },
        },
      );

      setIsOpenModalCashback(false);
      setToastList([
        ...toastList,
        populateToastMessagesGlobal(
          'success',
          'Sucesso',
          'Aguarde a confirmação do banco.',
          'main',
        ),
      ]);
      // TODO: Melhorar para depois de Reprocessar Cashback alterar o item na tabela ao invés de recarregar a página
      setTimeout(() => window.location.reload(), 2000);
    } catch (e) {
      setIsOpenModalCashback(false);
      setToastList([
        ...toastList,
        populateToastMessagesGlobal(
          'error',
          'Erro',
          'Houve um erro ao reprocessar essa transação.',
          'main',
        ),
      ]);
    }
  };

  const openModalDetail = async () => {
    try {
      const { data } = await handleGetRequest(
        `${window.SERVICES_DOMAIN}/global/v1/admin-painel/cashback-release/details/${cashback.id}`,
        {
          headers: initialHeadersData(keycloak.token),
        },
      );

      setModalDetailData(data);
      setModalDetailOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const statusMap = {
    FAILED_CASHBACK_PMA: 'Falha ao Processar Cashback',
    PROCESSING_PIX: 'Aguardando Pagamento via PIX',
    SUCCESS_PIX: 'Creditado via PIX',
  };

  return (
    <>
      <StyledTr>
        <td>{statusMap[cashback?.status]}</td>
        <td className="content">{cashback?.releaseDate}</td>
        <td>{cashback?.type}</td>
        <td className="content">{cashback?.paymentDate}</td>
        <td>{cashback?.lastOperator || '-'}</td>
        <td className="content">{cashback?.documentId || ''}</td>
        <td className="documentId">{cashback?.clientName}</td>
        <td>{cashback?.partnerName}</td>
        <td>{cashback?.createDate}</td>
        <td className="content">{cashback?.productValue}</td>
        <td className="content">{cashback?.cashbackValue}</td>
        <td>
          {cashback?.status === 'FAILED_CASHBACK_PMA' && (
            <>
              <WrapperButton>
                <button
                  type="button"
                  className="btn-action"
                  onClick={() => setIsOpenModalCashback(true)}
                >
                  Reprocessar Cashback
                </button>
              </WrapperButton>
            </>
          )}
          {cashback?.isProcessed && (
            <>
              <WrapperButton>
                <button
                  type="button"
                  className="btn-action2"
                  onClick={openModalDetail}
                >
                  Detalhes
                </button>
              </WrapperButton>
            </>
          )}
        </td>
      </StyledTr>
      <ModalDetails
        cashback={cashback}
        transactions={transaction}
        isOpenModal={modalDetailOpen}
        handleCloseModal={handleCloseModalDetail}
        modalDetailData={modalDetailData}
      />
      <ModalPix
        cashback={cashback}
        isOpenModal={isOpenModalCashback}
        handleCloseModal={handleCloseMdlCashback}
        userPermissions={userPermissions}
        handlerConfirmTransaction={confirmTransaction}
        setToastList={setToastList}
        toastList={toastList}
      />
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
    </>
  );
}
