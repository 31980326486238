import styled from 'styled-components';

export const SRadioButton = styled.div`
  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $gray-500;
  }

  .radio__button {
    display: none;
  }

  .radio__button:checked + .radio__checkmark {
    position: relative;
    background-color: #cc0000;
  }

  .radio__button:checked + .radio__checkmark::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .radio__checkmark {
    margin-right: 10px;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #cc0000;
    border-radius: 100%;
    color: #cc0000;
    cursor: pointer;
  }

  .radio__checkmark_disabled {
    opacity: 0.5;
  }

  .radio__text {
    cursor: pointer;
    color: #222222;
    font-size: 16px;
  }
`;
