import React, { useState } from 'react';

import Button from '../../Button';
import Modal from '../../Modal';
import ModalConfirm from '../ModalConfirm';
import RadioButton from '../../RadioButton';

import { WrapperButtons, Form } from './styles';

export default function ModalPix({
  isOpenModal,
  handleCloseModal,
  cashback,
  handleShowMessageAction,
  userPermissions,
  handlerConfirmTransaction,
}) {
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);

  const handleCloseMdlCashback = (status, title, message) => {
    if (status && title && message) {
      handleShowMessageAction(status, title, message);
    }
    setIsOpenModalConfirmation(false);
  };

  const confirmTransaction = () => {
    try {
      setIsOpenModalConfirmation(false);
      handlerConfirmTransaction();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        headerTitle="Detalhes da Transação"
      >
        <Form>
          <Form.Group>
            <Form.Label>
              <b>Cliente: </b> {cashback?.clientName}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> CPF/CNPJ: </b> {cashback?.documentId}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Parceiro: </b> {cashback?.partnerName}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Data da Compra: </b> {cashback?.createDate}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Valor da Compra: </b> {cashback?.productValue}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Valor do Cashback: </b> {cashback?.cashbackValue}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Tipo da Chave Pix: </b>
              <div className="type-pix">
                <RadioButton value="email" disabled onChange={() => {}}>
                  E-mail
                </RadioButton>
                <RadioButton value="telefone" disabled onChange={() => {}}>
                  Telefone
                </RadioButton>
                <RadioButton value="cpf" checked onChange={() => {}}>
                  CPF/CNPJ
                </RadioButton>
                <RadioButton
                  value="chaveAleatoria"
                  disabled
                  onChange={() => {}}
                >
                  Chave Aleatória
                </RadioButton>
              </div>
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <b> Chave Pix: </b> {cashback?.documentId}
            </Form.Label>
          </Form.Group>
        </Form>
        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            type="button"
            className="btn-action"
            onClick={() => setIsOpenModalConfirmation(true)}
          >
            Confirmar
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </Modal>
      <ModalConfirm
        className="modal"
        cashback={cashback}
        isOpenModal={isOpenModalConfirmation}
        handleCloseModal={handleCloseMdlCashback}
        userPermissions={userPermissions}
        confirmTransaction={confirmTransaction}
      />
    </>
  );
}
