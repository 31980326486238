import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import jwt from 'jsonwebtoken';
import Header from '../Header';
import SideMenu from '../SideMenu';
import constants from '../../../config';
import { findProp } from '../../../helpers/GlobalFunctions';
import Content from '../../Content';
import nucleoFavImg from '../../../assets/nucleo/header/Group17.svg';
import nucleoImg from '../../../assets/nucleo/header/Group16.svg';
import AppRouter from '../../../routes';
import DashboardContent from '../../Dashboard';

export default function Layout() {
  const [isSideMenuMobileOpen, setIsSideMenuMobileOpen] = useState(false);

  const handleSiMenuMobile = () => {
    setIsSideMenuMobileOpen(!isSideMenuMobileOpen);
  };

  const checkUiPermissionsFnDefault = () => {
    return false;
  };
  const [user, setUser] = useState(false);

  const [userPermissions, setUserPermissions] = useState({
    userGroups: ['default'],
    permissions: constants.DEFAULT_PERMISSIONS_GROUP,
    checkUiPermissionsFn: checkUiPermissionsFnDefault,
  });

  const checkUiPermissions = (nodeCondition, userGroups) => {
    try {
      const groupPermissions = findProp(
        userPermissions.permissions,
        nodeCondition,
      );

      const permissions = userGroups.filter(item =>
        groupPermissions.includes(item),
      );
      if (permissions.length > 0) return true;

      return false;
    } catch (e) {
      console.error(
        `Cannnot find condition in the list settings! ${nodeCondition}.`,
      );
      return false;
    }
  };

  const { keycloak, initialized } = useKeycloak();

  console.warn(keycloak.authenticated, initialized);

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    console.warn(keycloak.authenticated, initialized);
    if (keycloak.authenticated) {
      const decryptedToken = jwt.decode(keycloak.token);

      if (decryptedToken.groups) {
        setUserPermissions({
          userGroups: decryptedToken.groups,
          permissions: constants.DEFAULT_PERMISSIONS_GROUP,
          checkUiPermissionsFn: checkUiPermissions,
        });
      }

      keycloak
        .loadUserInfo()
        .then(userInfo => {
          setUser(userInfo);
        })
        .catch(error => {
          console.error(`Error keycloak ${error}`);
          handleLogout();
        });
    }
  }, [keycloak.authenticated, initialized]);

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <DashboardContent userData={user} />
        </Route>
        <Route path="*">
          <Header
            iconHeader={nucleoFavImg}
            imgHeader={nucleoImg}
            text="Beta"
            handleSideMenu={handleSiMenuMobile}
          />
          <SideMenu userPermissions={userPermissions} />

          <Content
            isSideMenuMobileOpen={isSideMenuMobileOpen}
            userPermissions={userPermissions}
          >
            <AppRouter userPermissions={userPermissions} />
          </Content>
        </Route>
      </Switch>
    </>
  );
}
