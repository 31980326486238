import styled from 'styled-components';

import { Form as FormBootstrap } from 'react-bootstrap';
import { Modal as ModalBootstrap } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  background: #08070794;
  font-family: 'Poppins';

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 580px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .close {
      color: #cc0000;
      font-weight: 100;
      font-size: 38px;
    }
    .modal-title {
      font-size: 1.3rem;
      font-weight: 800;
      padding-top: 10px;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }
    }
  }

  .type-pix {
    display: inline-flex;
    gap: 10px;
  }
`;

export const Form = styled(FormBootstrap)`
  margin-top: 4px;

  label {
    font-weight: 600;
  }

  textarea {
    min-height: 120px;
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 12px;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: center;

  button {
    width: 100%;

    & + button {
      margin-left: 1rem;
    }
  }
`;
