import React, { useState } from 'react';
import moment from 'moment';

import ModalRemoveProduct from '../ModalRemoveProduct';

import { StyledTr, WrapperButton } from './styles';

export default function BlockContactItem({
  item,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalRemove, setIsOpenModalRemove] = useState(false);

  const handleShowMdlRemoveProduct = event => {
    event.stopPropagation();
    setIsOpenModalRemove(true);
  };

  const handleCloseMdlRemoveProduct = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalRemove(false);
  };

  return (
    <>
      <StyledTr>
        <td>{item.contact}</td>
        <td>{item.contactType}</td>
        <td>{moment(item.createAt).format('DD/MM/YYYY HH:mm')}</td>
        <td>
          <WrapperButton>
            {userPermissions.checkUiPermissionsFn(
              'ESFERA.CONTACT_BLOCK_LIST.DELETE',
              userPermissions.userGroups,
            ) ? (
              <button
                type="button"
                className="btn-action"
                onClick={handleShowMdlRemoveProduct}
              >
                <span className="recused">Remover</span>
              </button>
            ) : null}
          </WrapperButton>
        </td>
      </StyledTr>

      <ModalRemoveProduct
        item={item}
        isOpenModal={isOpenModalRemove}
        handleCloseModal={handleCloseMdlRemoveProduct}
        userPermissions={userPermissions}
      />
    </>
  );
}
