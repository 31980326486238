import styled from 'styled-components';

export const StyledTr = styled.tr`
  background: ${props => (props.isActive ? 'var(--white)' : 'var(--gray-500)')};
  vertical-align: center;

  td {
    vertical-align: middle;
    white-space: pre-line;
  }

  .td-center {
    text-align: center;
    padding: 0;
  }

  .btn-history {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    color: #cc0000;
  }

  .btn-history:hover {
    color: #990000;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 0.8rem;

    &.update {
      color: var(--green-50);
    }

    &.recused {
      color: var(--boston);
    }
  }

  > span {
    margin-left: 10px;
  }

  .btn-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 7px 2px;
    width: 75px;
    background: transparent;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: var(--gray-500);
    }

    & + .btn-action {
      margin-left: 10px;
    }

    img {
      margin-bottom: 2px;
      width: 20px;
      height: 20px;
    }
  }
`;
