import React, { useEffect, useRef } from 'react';
import * as S from './styles';

export default function Accordion({
  id,
  title,
  isActive: isActiveProps,
  bodyAccordion,
  iconClassName,
}) {
  const [isActive, setIsActive] = useRef(Boolean(!isActiveProps));
  let toggle;
  const iconClass = `icon-button esfera-icon ${iconClassName}`;

  useEffect(() => {
    toggle.click();
  }, []);
  return (
    <S.Accordion defaultActiveKey={id}>
      <S.Accordion.Toggle
        ref={input => {
          toggle = input;
        }}
        as={S.HeaderAccordion}
        eventKey={id}
        onClick={() => setIsActive(!isActive)}
      >
        {!!iconClassName && <span className={iconClass} />}
        <p>{title}</p>
        {isActive ? (
          <span className="icon-button esfera-icon ic_system_arrow_down" />
        ) : (
          <span className="icon-button esfera-icon ic_system_arrow_top" />
        )}
      </S.Accordion.Toggle>
      <S.Accordion.Collapse eventKey={id}>{bodyAccordion}</S.Accordion.Collapse>
    </S.Accordion>
  );
}
