import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../Button';
import Loading from '../../Loading';
import constants from '../../../config';

import { StyledModal, WrapperButtons, Form } from './styles';

import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import {
  formatErrorMessageToToast,
  formatPhone,
  isValidEmail,
} from '../../../helpers/GlobalFunctions';

export default function ModalAddProduct({
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [contactIds, setContactIds] = useState('');
  const [validePhone, setValidatePhone] = useState('');
  const [isValidInput, setIsValidInput] = useState(false);
  const [blockContactType, setBlockContactType] = useState('PHONE');
  const FULL_URL = window.SERVICES_DOMAIN + window.BLOCK_CONTACT_ADD_URL;

  const [showLoading, setShowLoading] = useState(false);

  const handleFormatPhone = phone => {
    let cleanPhone = phone.replace(/\D/g, '');
    if (cleanPhone.length > 11) {
      cleanPhone = cleanPhone.slice(0, 11);
    }
    setIsValidInput(cleanPhone.length >= 10);
    setValidatePhone(formatPhone(cleanPhone));
    setContactIds(cleanPhone);
  };

  const handlePhone = e => {
    const phone = e.target.value;
    handleFormatPhone(phone);
  };

  const handleBlockContactType = e => {
    const contactType = e.target.value;
    setBlockContactType(contactType);
    if (contactType === 'EMAIL') setIsValidInput(isValidEmail(contactIds));
    else {
      handleFormatPhone(contactIds);
    }
  };

  const handleContactIds = e => {
    const contact = e.target.value;
    setIsValidInput(isValidEmail(contact));
    setContactIds(contact);
    setValidatePhone(contact);
  };

  const handleAddProductToBlockContact = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CONTACT_BLOCK_LIST.CREATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          contact: contactIds,
          contactType: blockContactType,
        },
      };

      handlePostRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data) {
            setContactIds('');
            setValidatePhone('');
            setShowLoading(false);
            setBlockContactType('PHONE');
            handleCloseModal(
              'success',
              'Sucesso!',
              `Contatos adicionados na lista.`,
            );
          }
        })
        .catch(error => {
          setContactIds('');
          setValidatePhone('');
          setShowLoading(false);
          setBlockContactType('PHONE');
          console.error('Error at addContactsToBlacklist request', error);
          console.error(`Error at addContactsToBlacklist request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Adicionar contatos</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Tipo de contato: </Form.Label>
            <Form.Control
              as="select"
              value={blockContactType}
              onChange={handleBlockContactType}
            >
              {Object.keys(constants.CONTACT_BLOCK_LIST_TYPES).map(item => (
                <option value={item} key={item}>
                  {constants.CONTACT_BLOCK_LIST_TYPES[item]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              {blockContactType === 'EMAIL'
                ? 'Informe o E-mail'
                : 'Informe o Telefone'}
            </Form.Label>
            <Form.Control
              as="input"
              value={blockContactType === 'EMAIL' ? contactIds : validePhone}
              onChange={
                blockContactType === 'EMAIL' ? handleContactIds : handlePhone
              }
              placeholder={
                blockContactType === 'EMAIL'
                  ? 'Digite o e-mail'
                  : 'Digite o telefone'
              }
              maxLength={blockContactType === 'EMAIL' ? 50 : 15}
            />
          </Form.Group>
        </Form>

        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            disabled={!isValidInput}
            onClick={handleAddProductToBlockContact}
          >
            Adicionar
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
