import React, { useEffect, useState } from 'react';
import Button from '../../Button';
import { StyledForm } from './styles';

export default function OrdersFilter({
  allPartner,
  currentFilter,
  handleFilter,
}) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [saleAmountMax, setSaleAmountMax] = useState('');
  const [saleAmountMin, setSaleAmountMin] = useState('');
  const [partner, setPartner] = useState('DEFAULT');
  const [transaction, setTransaction] = useState('');
  const [status, setStatus] = useState('');

  const sortByPartner = () => {
    const orderPartners = [...allPartner].sort();
    return orderPartners;
  };

  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleEndDate = e => {
    setEndDate(e.target.value);
  };

  const handleSaleAmountMin = e => {
    setSaleAmountMin(e.target.value);
  };

  const handleSaleAmountMax = e => {
    setSaleAmountMax(e.target.value);
  };

  const handlePartner = e => {
    setPartner(e.target.value);
  };

  const handleTransaction = e => {
    setTransaction(e.target.value);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.partner;
    delete filter.saleAmountMin;
    delete filter.saleAmountMax;
    delete filter.transaction;
    delete filter.status;

    setStartDate('');
    setEndDate('');
    setPartner('DEFAULT');
    setSaleAmountMin('');
    setSaleAmountMax('');
    setTransaction('');
    setStatus('');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.saleAmountMin;
    delete filter.saleAmountMax;
    delete filter.transaction;
    delete filter.partner;
    delete filter.status;

    if (startDate) filter.startDate = startDate;
    if (endDate) filter.endDate = endDate;
    if (partner !== 'DEFAULT') filter.partner = partner;
    if (saleAmountMax) filter.saleAmountMax = saleAmountMax;
    if (saleAmountMin) filter.saleAmountMin = saleAmountMin;
    if (transaction) filter.transaction = transaction;
    if (status) filter.status = status;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid =
      partner !== 'DEFAULT' ||
      startDate ||
      endDate ||
      saleAmountMax ||
      saleAmountMin ||
      transaction ||
      status;
    return isValid;
  };

  useEffect(() => {
    setStartDate('');
    setEndDate('');
    setSaleAmountMin('');
    setSaleAmountMax('');
    setTransaction('');
    setStatus('');
    setPartner('DEFAULT');
  }, [currentFilter]);

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Data início:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleStartDate}
            type="date"
            value={startDate}
          />
          <StyledForm.Label>Data fim:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleEndDate}
            type="date"
            value={endDate}
          />
        </StyledForm.Group>

        <StyledForm.Group>
          <StyledForm.Label>Loja: </StyledForm.Label>
          <StyledForm.Control
            as="select"
            value={partner}
            onChange={handlePartner}
          >
            <option disabled value="DEFAULT">
              Todas as lojas
            </option>
            {sortByPartner().map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>
        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>ID de transação (Awin):</StyledForm.Label>
            <StyledForm.Control
              onChange={handleTransaction}
              type="text"
              value={transaction}
            />
          </StyledForm.Group>
          <StyledForm.Group className="form-group">
            <StyledForm.Label> Status do (Awin): </StyledForm.Label>
            <StyledForm.Control
              onChange={handleStatus}
              type="text"
              value={status}
            />
          </StyledForm.Group>
        </div>
        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Valor da Compra (Min):</StyledForm.Label>
            <StyledForm.Control
              onChange={handleSaleAmountMin}
              type="text"
              value={saleAmountMin}
            />
          </StyledForm.Group>
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Valor da Compra (Max):</StyledForm.Label>
            <StyledForm.Control
              onChange={handleSaleAmountMax}
              type="text"
              value={saleAmountMax}
            />
          </StyledForm.Group>
        </div>
      </div>
      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button
          onClick={clearFilter}
          type="button"
          className="btn-clear-filter"
        >
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
