import styled from 'styled-components';
import AccordionBootstrap from 'react-bootstrap/Accordion';

export const AccordionStyle = styled(AccordionBootstrap)`
  .collapse {
    display: block !important;
    max-height: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in !important;
    transition-property: all !important;
    overflow: hidden;
    border-top: 0px solid #707070;
  }

  .collapse.show {
    max-height: 200px;
    border-top: 1px solid #707070;
  }
  .button {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }
  .title {
    align-items: center;
    padding-left: 10px;
    display: flex;
    height: 50px;
    font-weight: 500;
    cursor: pointer;
  }
  .icon {
    align-self: center;
    padding-right: 20px;
  }
`;
